const testimonialData = [
  {
    id: 1,
    image: "https://....jpg",
    name: "Adele",
    title:
      "Director of Safeguarding and Family Support at Telford County Council",
    quote:
      "Mary-anne’s talks and sessions should be mandatory for everyone who works with young people in care, there is so much to learn and take away from what she offers, we can’t wait to work with her again",
  },
  {
    id: 2,
    image: "https://....jpg",
    name: "Sarah",
    title:
      "Foster Parent with Blue Sky Fostering: ‘Let’s Talk Words’- Language in Care Training.",
    quote:
      "One of the best trainings I’ve ever been to, you capture the child’s perspective in a way I’ve not experienced before, so powerful.",
  },
  {
    id: 3,
    image: "https://....jpg",
    name: "Leaving Care Team Practitioner",
    title: "Barking and Dagenham County Council",
    quote:
      "Thank you so much for your training today, it was absolutely incredible. It was easy to see how passionate you are about changing the way young people experience care and the care system, and your training today will make me think more about how I record and write about/with my young people. Thank you again for an outstanding day",
  },
  {
    id: 4,
    image: "https://....jpg",
    name: "ASYE Practitioner",
    title: "Cambridgeshire County Council",
    quote:
      "Mary was brilliant, one of the best speakers I’ve heard. Mary obviously has the academic knowledge and the way she shared her knowledge with us really hit the right note for me. Mary’s lived experience made the session so valuable and relatable, I could have happily listened to her all day.",
  },
  {
    id: 5,
    image: "https://....jpg",
    name: "Gemma",
    title:
      "Leaving Care Team Practitioner at Barking and Dagenham County Council",
    quote:
      "​The most impactful and thought provoking training, this will stay with me for a long time and I hope impact my practice for the positive.",
  },
  {
    id: 6,
    image: "https://....jpg",
    name: "Foster Carer",
    title: "Blue Sky Fostering",
    quote:
      "Thank you Mary-Anne; a sensitive, thought-provoking and specialised training delivered with clarity and passion. Thank you for sharing your care experiences to support and empower our own as foster carers for the children in our care.",
  },
  {
    id: 7,
    image: "https://....jpg",
    name: "Practice Lead",
    title: "Adult Social Work",
    quote:
      "I can't think of a better training opportunity than to hear from a person's lived experience. The words and content were powerful and humbling at the same time. I will remember this training, because it was emotive, personal and inspiring. As a practice lead in adult's social work I will continue to promote this approach and encourage colleagues to re-evaluate their own practice.",
  },
  {
    id: 8,
    image: "https://....jpg",
    name: "Foster Parent",
    title: "Nexus Fostering",
    quote:
      "I wish I had experienced this session when I first started Fostering. Our training has always been good, however, this subject was only touched upon. Seeing the profound effect of words from the view of a care experienced person can only help caregivers and professionals to elevate children's and young people's self-worth and confidence and to feel heard, valued and present.",
  },
];

export default testimonialData;
