import { ServerError, ClientError, FormatError } from "errors";
import {
  validateString,
  validateNumber,
  validateCallback,
  validateToken,
  validateText,
  validateFile,
  validateStatus,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateAssociate(
  token,
  action,
  route,
  associateDataToUpdate,
  callback
) {
  const { status, name, title, newRoute, rank, bodyHtml, mainImage, url } =
    associateDataToUpdate;

  //====== validation ======//
  validateToken(token);
  validateText(action, "action");
  validateCallback(callback);

  if (action === "details") {
    validateStatus(status);
    validateString(url, "url");
    validateString(title, "title");
    validateNumber(rank, "position");
    // name and newRoute a required fields
    validateText(name, "name");
    validateText(newRoute, "new route");
  }

  if (action === "content") {
    validateString(bodyHtml, "body HTML");
  }

  if (action === "mainImage") {
    validateFile(mainImage, "main image");
  }

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/associates/${route}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  if (action === "details")
    xhr.send(
      JSON.stringify({
        action: action,
        name: name,
        title: title,
        newRoute: newRoute,
        status: status,
        rank: rank,
        url: url,
      })
    );
  else if (action === "content")
    xhr.send(
      JSON.stringify({
        action: action,
        bodyHtml: bodyHtml,
      })
    );
  else if (action === "mainImage")
    xhr.send(
      JSON.stringify({
        action: action,
        mainImage: mainImage,
      })
    );
}

export default updateAssociate;
