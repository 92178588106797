import "./MyCarouselAlignLeft.css";

import { useState, useEffect, useRef, useLayoutEffect } from "react";
import Loggito from "../../utils/Loggito";
import { getAssociatesBusinessClient } from "../../logic";
import { ServerError } from "errors";
import { toast } from "react-toastify";
import { useSwipeable } from "react-swipeable";

const MyCarouselAssociatesBusiness = () => {
  const [activeIndex, setActiveIndex] = useState(2);
  const [associatesArray, setAssociatesArray] = useState([]);
  const [paused, setPaused] = useState(false);

  const logger = new Loggito("MyCarouselAssociatesBusiness");

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeIndex === associatesArray.length - 2) setActiveIndex(2);
      else setActiveIndex(activeIndex + 1);
    },
    onSwipedRight: () => {
      if (activeIndex === 1) setActiveIndex(associatesArray.length - 3);
      else setActiveIndex(activeIndex - 1);
    },
  });

  useEffect(() => {
    // moves the full card row forward depending on screen size/ how many cards are displayed, 3 - 2 - 1, before, only the else option existed
    // applied only to the automatic interval, all other movements move 1 at a time
    // for rows of three it is better to have e.g. 10 cards with 4 repeated when skipping 2 or 3 cards - before had 9 with 3 repeated
    // for three cards all cards are seen, for two cards the final one doesn't show, for one card the final two don't show
    // visually on screen this looks okay, but the flow of cards is no longer a continuous loop - it does not start where it stops
    if (window.screen.width >= 1024) {
      const interval = setInterval(() => {
        if (!paused) {
          if (activeIndex >= associatesArray.length - 2) setActiveIndex(2);
          // these coditionals nudge the carousel to show all but not pass the limit of the array
          else if (activeIndex === associatesArray.length - 3)
            setActiveIndex(activeIndex + 1);
          else if (activeIndex === associatesArray.length - 4)
            setActiveIndex(activeIndex + 2);
          else setActiveIndex(activeIndex + 3);
        }
      }, 2500);

      return () => {
        if (interval) clearInterval(interval);
      };
    } else if (window.screen.width >= 700) {
      const interval = setInterval(() => {
        if (!paused) {
          if (activeIndex >= associatesArray.length - 2) setActiveIndex(2);
          else if (activeIndex === associatesArray.length - 3)
            setActiveIndex(activeIndex + 1);
          else setActiveIndex(activeIndex + 2);
        }
      }, 2500);

      return () => {
        if (interval) clearInterval(interval);
      };
    } else {
      const interval = setInterval(() => {
        if (!paused) {
          if (activeIndex >= associatesArray.length - 2) setActiveIndex(2);
          else setActiveIndex(activeIndex + 1);
        }
      }, 2500);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  });

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getAssociatesBusinessClient((error, associates) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }

        if (associates.length > 2) {
          const infiniteArray = [
            associates[associates.length - 2],
            associates[associates.length - 1],
            ...associates,
            associates[0],
          ];

          setAssociatesArray(infiniteArray);
        } else setAssociatesArray(associates);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  const moveForwards = () => {
    if (activeIndex === associatesArray.length - 2) setActiveIndex(2);
    else setActiveIndex(activeIndex + 1);
  };

  const moveBackwards = () => {
    if (activeIndex === 1) setActiveIndex(associatesArray.length - 3);
    else setActiveIndex(activeIndex - 1);
  };

  return (
    <div
      className="carousel-container"
      {...handlers}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <Carousel
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        className="carousel-component"
      >
        {associatesArray.map((card, i) => {
          return (
            <CarouselCard
              key={`${card.id}${Math.floor(
                Math.random() * 1000000000000
              )}${Date.now()}`}
              active={activeIndex === i}
            >
              <div className="carousel-card-content">
                <a
                  href={`${card.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="carousel-card-content card-image"
                    style={{
                      backgroundImage: `url(${card.mainImage.fileUrl})`,
                    }}
                  ></div>
                </a>
                {card.link ? (
                  <a
                    className="link"
                    href={`${card.link}`}
                    title={`${card.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h4 className="card-title">
                      {card.title.length < 95
                        ? card.title
                        : card.title.substring(0, 95) + "..."}
                    </h4>
                  </a>
                ) : (
                  <h4 className="card-title">
                    {card.title.length < 95
                      ? card.title
                      : card.title.substring(0, 95) + "..."}
                  </h4>
                )}
              </div>
            </CarouselCard>
          );
        })}
      </Carousel>
      <div className="button-group">
        <button
          type="button"
          disabled={activeIndex === 0}
          onClick={moveBackwards}
        >
          Prev
        </button>
        <button
          type="button"
          disabled={activeIndex === associatesArray.length - 1}
          onClick={moveForwards}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MyCarouselAssociatesBusiness;

const Carousel = ({ activeIndex, setActiveIndex, children }) => {
  const carouselRef = useRef(null);
  const [carouselTranslate, setCarouselTranslate] = useState(null);

  const [size, setSize] = useState([0, 0]);

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  useWindowSize();

  useEffect(() => {
    // offsetWidth is the viewable area of the element, all except margin. If we have 4 elements in the coarousel we divide by 8 to move the first item left by half
    // /8 for 4 elements in carousel
    // const initialTranslateVal = carouselRef.current.offsetWidth / 8;
    // for 3 elements in carousel
    let initialTranslateVal;

    if (window.screen.width >= 1024) {
      initialTranslateVal = carouselRef.current.offsetWidth / (2 / (1 / 3));
    } else if (window.screen.width >= 700) {
      initialTranslateVal = carouselRef.current.offsetWidth / (2 / (1 / 2));
    } else {
      initialTranslateVal = carouselRef.current.offsetWidth / 2;
    }
    // the offsetWidth is then multiplied by two to get the original element width
    const diffAmount = initialTranslateVal * 2;

    let translate;

    if (window.screen.width >= 700) {
      translate =
        activeIndex === 0
          ? initialTranslateVal + initialTranslateVal / 1.2
          : initialTranslateVal -
            activeIndex * diffAmount +
            initialTranslateVal / 1.2;
    } else {
      translate =
        activeIndex === 0
          ? initialTranslateVal + initialTranslateVal / 1.2
          : initialTranslateVal -
            activeIndex * diffAmount +
            initialTranslateVal / 1;
    }
    setCarouselTranslate(translate);
  }, [activeIndex, size]);

  return (
    <>
      <div
        className="carousel"
        ref={carouselRef}
        style={{ transform: `translateX(${carouselTranslate}px)` }}
      >
        {children}
      </div>
      <div className="dots">
        {/* new implementation removes unnecessary dots at the beginning and end */}
        {/* {children.map((child, i) => ( */}
        {children.slice(1, children.length - 1).map((child, i) => (
          <button
            key={`${i}${Math.floor(
              Math.random() * 1000000000000
            )}${Date.now()}`}
            // {/* new implementation removes unnecessary dots at the beginning and end */}
            // className={`dot ${activeIndex === i ? "active" : ""}`}
            className={`dot ${activeIndex === i + 1 ? "active" : ""}`}
            onClick={() => setActiveIndex(i)}
          />
        ))}
      </div>
    </>
  );
};

const CarouselCard = ({ active, children }) => {
  return (
    <div className={`carousel-card ${active ? "active" : ""}`}>{children}</div>
  );
};
