import React from "react";
import { FaQuoteRight } from "react-icons/fa";

import {
  Audience,
  BlockPlansWorkingWith,
  NextSteps,
  WorkingWithMary,
} from "../../";

import "./AboutPage.css";

function AboutPage() {
  return (
    <div>
      <div className="content about-page">
        <h2 className="h2 about-title p--centered">About Mary-anne:</h2>
        <div className="about-me-text">
          <p className="p--medium p--centered">
            Mary-anne is care-experienced, having spent over half of her
            childhood in foster care, a journey that became permanent from the
            age of 9. She is a qualified PGCE teacher, whose subject specialism
            is Psychology (BSc). Mary-anne is proud to be leveraging this unique
            combination of experiences to incite positive change, and improved
            outcomes, for children and young people who have experienced early
            adversity.
          </p>
        </div>
        <div className="testimonials">
          <div className="testimonial">
            <div className="quote-icon-container">
              <FaQuoteRight className="icon" />
            </div>
            <p className="testimony p--medium">
              "In her work, Mary-Anne uses her life, her story and her
              experiences to take foster carers and professionals on a journey
              that inspires hope. A hope that they can make a difference by
              listening to and engaging with the way she frames the key moments
              and people within her life and what they did to help. Her
              knowledge and understanding of development, trauma and attachment
              means she can bridge the gap between lived experience and aiding
              the development of carers and children's services practitioners”
            </p>
            <p className="person">
              <strong>Ed Thompson:</strong> Strategic Projects Manager At BSN
            </p>
          </div>
        </div>

        <WorkingWithMary />
        <BlockPlansWorkingWith />
        <Audience />

        <NextSteps />
      </div>
    </div>
  );
}

export default AboutPage;
