import { ServerError, ClientError, FormatError } from "errors";
import {
  validateCallback,
  validateToken,
  validateText,
  validateFile,
  validateStatus,
  validateBoolean,
  validateString,
  validateDate,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateNews(token, action, route, newsDataToUpdate, callback) {
  const {
    title,
    newRoute,
    // category,
    status,
    editorsPick,
    tags,
    video,
    // noteToSubscribers,
    date,
    newsHtml,
    mainImage,
    file1,
    file2,
    file3,
  } = newsDataToUpdate;

  //====== validation ======//
  validateToken(token);
  validateText(action, "action");
  validateCallback(callback);

  if (action === "details") {
    validateText(title, "title");
    validateText(newRoute, "new route");
    validateStatus(status);
    validateBoolean(editorsPick, "editor's pick");
    validateString(tags, "tags");
    validateString(video, "video");
    if (!!date) validateDate(date);
  }

  if (action === "content") {
    validateString(newsHtml, "news HTML");
  }

  if (action === "mainImage") {
    validateFile(mainImage, "main image");
  }
  if (action === "file1") {
    validateFile(file1, "file 1");
  }
  if (action === "file2") {
    validateFile(file2, "file2");
  }
  if (action === "file3") {
    validateFile(file3, "file3");
  }

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/news/${route}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  if (action === "details")
    xhr.send(
      JSON.stringify({
        action: action,
        title: title,
        newRoute: newRoute,
        status: status,
        editorsPick: editorsPick,
        tags: tags,
        video: video,
        date: date,
      })
    );
  else if (action === "content")
    xhr.send(
      JSON.stringify({
        action: action,
        newsHtml: newsHtml,
      })
    );
  else if (action === "mainImage")
    xhr.send(
      JSON.stringify({
        action: action,
        mainImage: mainImage,
      })
    );
  else if (action === "file1")
    xhr.send(
      JSON.stringify({
        action: action,
        file1: file1,
      })
    );
  else if (action === "file2")
    xhr.send(
      JSON.stringify({
        action: action,
        file2: file2,
      })
    );
  else if (action === "file3")
    xhr.send(
      JSON.stringify({
        action: action,
        file3: file3,
      })
    );
}

export default updateNews;
