// ========== Imports ========== //

import "./DeleteSubscriber.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { deleteSubscriber } from "../../../logic";
import { toast } from "react-toastify";
import Loggito from "../../../utils/Loggito";
import { ServerError } from "errors";

// ========== Component ========== //

//Receives the eventId and try to delete that one in our database
function DeleteSubscriber({ subscriberEmail }) {
  // ========== Hook consts ========== //

  const [deleteDivStyle, setDeleteDivStyle] = useState({
    display: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-50px",
    width: "100px",
    height: "100px",
  });
  const [pageBlockStyle, setPageBlockStyle] = useState({
    display: "none",
  });

  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("DeleteSubscriber");

  // const eventIdString = eventId.toString();

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  function refreshPage() {
    window.location.reload(false);
  }

  // ========== Function to block the screen and show the accept/decline popup ========== //

  function changeDivStatus() {
    if (deleteDivStyle.display === "none") {
      setPageBlockStyle({
        display: "block",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "black",
        opacity: "50%",
      });
      setDeleteDivStyle({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        color: "var(--color-primary-text)",
        flexDirection: "column",
        boxShadow: "0 0 20px 10px hsl(0deg 0% 15%)",
        position: "fixed",
        top: "50%",
        left: "50%",
        padding: "20px",
        marginTop: "-75px",
        marginLeft: "-100px",
        width: "300px",
        height: "300px",
        borderRadius: "10px",
      });
    } else {
      setDeleteDivStyle({ display: "none" });
      setPageBlockStyle({ display: "none" });
    }
  }

  // ========== Functions ========== //

  function deleteSubscriberFromDatabase(subscriberEmail, location) {
    try {
      deleteSubscriber(sessionStorage.token, subscriberEmail, (error) => {
        // TODO: onload on logic is never triggered even if the delete event and response is sent correctly
        // TODO: code never reaches this far, it never returns to this function after sending
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        toast.success("The subscriber was successfully deleted");
        // handleGetSubscribers();
        refreshPage();
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  }

  // ========== JSX ========== //

  return (
    <div className="delete-subscriber">
      <button className="button button--table" onClick={changeDivStatus}>
        Delete
      </button>
      <div style={pageBlockStyle}></div>
      <div style={deleteDivStyle} className="p--medium">
        <p>You are going to delete the subscriber.</p>
        <p> Are you sure? </p>
        <div>
          <button
            className="button button--white-inverse button--delete-subscriber"
            onClick={() =>
              deleteSubscriberFromDatabase(subscriberEmail, location)
            }
          >
            Yes
          </button>
          <button
            className="button button--white-inverse button--delete-subscriber"
            onClick={changeDivStatus}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteSubscriber;
