import { ServerError, ClientError } from "errors";
import {
  validateToken,
  validateCallback,
  validateText,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function getUpcomingEventsItem(token, route, callback) {
  validateText(route, "route");
  validateToken(token);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 200) {
      const event = xhr.responseText;

      const eventParsed = JSON.parse(event);
      callback(null, eventParsed);
    }
  };

  xhr.open("GET", `${API_URL}/upcomingevents/admin/${route}`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.send();
}

export default getUpcomingEventsItem;
