import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { CTA } from "../../";

import "./Services.css";

function Services() {
  return (
    <section className="services">
      <div className="block container">
        <header className="block__header">
          <h2 className="h2 services-title">
            From professional development opportunities to inspiring speeches,
            through to service design and development, click below to learn more
            about Mary-anne’s offerings.
          </h2>
          <CTA text="Find out more" route="/contact" />
        </header>
        <article className="grid grid--1x2--25 feature">
          <div className="leaf-badge-container">
            <Link to="/training">
              <img
                className="leaf-badge"
                src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_training.png"
                alt=""
              />
            </Link>
          </div>
          <div className="feature__content">
            {/*   <span className="icon-container">
            </span> */}
            <h3 className="h3 feature__heading">Training</h3>
            <p>
              Training services provide meaningful development for professionals
              working in Children’s Services, specialising in supporting
              children in care. Sessions offer unique insights into the
              challenges faced by the care-experienced community and provide
              practical strategies for promoting well-being and positive
              outcomes, where children and young people thrive.
            </p>
            <div>
              <Link to="/training" className="link-arrow">
                Learn More
                <HiArrowNarrowRight className="arrow" />
              </Link>{" "}
            </div>
          </div>
        </article>
        <article className="grid grid--1x2--25 feature reverse">
          <div className="leaf-badge-container">
            <Link to="/keynotes">
              <img
                className="leaf-badge"
                src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_keynotes.png"
                alt=""
              />
            </Link>
          </div>
          <div className="feature__content">
            <h3 className="h3 feature__heading">Keynotes</h3>
            <p>
              Compelling speeches that draw from personal experience and a
              background in psychology. Prioritising practical insights to
              inspire positive change, the speeches leave a lasting impression
              on the audience, empowering them with a renewed sense of purpose
              and possibility in their work.
            </p>
            <div>
              <Link to="/keynotes" className="link-arrow">
                Learn More
                <HiArrowNarrowRight className="arrow" />
              </Link>{" "}
            </div>
          </div>
        </article>
        <article className="grid grid--1x2--25 feature">
          <div className="leaf-badge-container">
            <Link to="/consultancy">
              <img
                className="leaf-badge"
                src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_mh.png"
                alt=""
              />
            </Link>
          </div>
          <div className="feature__content">
            <h3 className="h3 feature__heading">Consultancy</h3>
            <p>
              Offering tailored support to organisations and professionals
              working with individuals who have experienced adversity, with a
              focus on those with care experience. Example services include
              policy and practice development and service design and
              implementation, based on a trauma- informed and strengths-based
              approach.
            </p>
            <div>
              <Link to="/consultancy" className="link-arrow">
                Learn More
                <HiArrowNarrowRight className="arrow" />
              </Link>{" "}
            </div>
          </div>
        </article>
        <article className="grid grid--1x2--25 feature">
          <div className="leaf-badge-container">
            <Link to="/the-guarantor-scheme">
              <img
                className="leaf-badge"
                src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_guarantor.png"
                alt=""
              />
            </Link>
          </div>
          <div className="feature__content">
            <h3 className="h3 feature__heading">The Guarantor Scheme</h3>
            <p>
              The Guarantor Scheme is an initiative that enables the Local
              Authority to act as the Corporate Guarantor for their Care Leavers
              who wish to enter the private rental sector. To learn more and
              request the Scheme in your area, click here.
            </p>
            <div>
              <Link to="/the-guarantor-scheme" className="link-arrow">
                Learn More
                <HiArrowNarrowRight className="arrow" />
              </Link>{" "}
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

export default Services;
