import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { NavbarHome } from "../../Components";
import Titles from "./Titles";

function HeaderMobileTablet() {
  return (
    <header className="home-page__header mobile-tablet--block">
      <div className="desktop--block social-media-icons-container">
        <div>
          <a
            href="https://twitter.com/maryhodd"
            title="Follow us on Twitter (opens new tab)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsTwitter className="nav__media-icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/mary-anne-hodd-376780105/"
            title="Follow us on LinkedIn (opens new tab)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsLinkedin className="nav__media-icon" />
          </a>
          <a
            href="https://www.instagram.com/maryannehodd/"
            title="Follow us on Instagram (opens new tab)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsInstagram className="nav__media-icon" />
          </a>
        </div>
      </div>

      <NavbarHome />
      <Titles />
    </header>
  );
}

export default HeaderMobileTablet;
