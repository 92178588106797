import React from "react";
import { FaQuoteRight } from "react-icons/fa";

import "./About.css";

function About(props) {
  return (
    <div className="about">
      <h2 className="h2 about-title">About</h2>
      <div>
        <div className="grid grid--1x2 grid--1x2--32">
          <div className="about-me-text">
            <p className="p--medium">
              Training, consultancy and keynotes in the field of children’s
              social care, specialising in children in care, care leavers, and
              those with a social worker.
            </p>
            <p className="p--medium">
              If you’re a foster carer, social worker, teacher or someone who
              works with or around children and young people in care,
              Mary-anne’s services are for you. Mary-anne’s practice combines
              her lived experience of growing up in foster care, with her
              background in psychology to equip you with practical resources and
              insights that support children and young people thriving.
            </p>
          </div>
          <div className="about-quote-container">
            <FaQuoteRight className="icon" />
            <p className="p--medium-large">
              "When a flower doesn't bloom you fix the environment in which it
              grows, not the flower" -{" "}
              <span className="p--medium p--bold">Alexander Den Heijer.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
