// ========== imports ========== //
import "./Associates.css";

import Loggito from "../../utils/Loggito";
import withContext from "../../utils/withContext";

import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { getAssociatesClient } from "../../logic";
import { NextSteps, MyCarouselAssociatesBusiness } from "../";
import Pagination from "../CommonPageElements/Pagination";

import { toast } from "react-toastify";

import { ServerError } from "errors";

// ========== Page ========== //

function Associates() {
  // ========== Hook consts ========== //
  const [associatesArray, setAssociatesArray] = useState([]);

  const location = useLocation();
  // ========== other consts ========== //

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 6;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return associatesArray.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const logger = new Loggito("Associates");

  // ========== useEffects ========== //
  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getAssociatesClient((error, associates) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        setAssociatesArray(associates);
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);
  // ========== jsx ========== //

  return (
    <div className="associates-new">
      <div className="content">
        {location.pathname === "/associates" && (
          <div>
            <div className="block">
              <div className="associates-carousel-container"></div>
              <h2 className="h2">
                Mary-anne works with the following organisations to provide the
                services outlined below:
              </h2>
            </div>
            <ul className="associates list">
              {currentTableData.map((associate) => {
                return (
                  <li className="associate" key={associate.name}>
                    <img
                      className="associate__image"
                      src={associate.mainImage.fileUrl}
                      alt={associate.name}
                    />
                    {associate.url ? (
                      <a
                        href={associate.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="associate__link"
                      >
                        <p className="p--medium p--bold p--associate">
                          {associate.name}
                        </p>
                        <p className="p--small p--bold p--associate p--no-break">
                          {associate.title}
                        </p>
                      </a>
                    ) : (
                      <div>
                        <p className="p--medium p--bold p--associate">
                          {associate.name}
                        </p>
                        <p className="p--small p--bold p--associate p--no-break">
                          {associate.title}
                        </p>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
            <Pagination
              // classname here seems to be irrelevant
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={associatesArray.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
        <div className="associates-carousel-container">
          <h2 className="h2">Working with and supporting</h2>
          <MyCarouselAssociatesBusiness />
        </div>
        <NextSteps />
      </div>
    </div>
  );
}

export default withContext(Associates);
