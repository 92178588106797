import React, { useRef } from "react";
import "./Home.css";
import {
  About,
  MyCarouselEditorsPicks,
  NextSteps,
  SliderTestimonials,
  MyCarouselMentions,
  UpcomingEvents,
  ServicesOverview,
} from "../../";

function Home() {
  const aboutMe = useRef();
  const testimonials = useRef();

  return (
    <div>
      <div className="content home">
        <div className="home-picture-mary">
          <div className="block block--left"></div>
          <img
            src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/mary_main_original.jpeg"
            alt="Mary-anne Hobbs"
          ></img>
          <div className="block block--right"></div>
        </div>

        <div className="block">
          <About />
          <div className="line--secondary"></div>
          <ServicesOverview />
          <div className="line--secondary"></div>
          <SliderTestimonials />
          <div className="line--secondary"></div>
          <MyCarouselMentions />
          <div className="line--secondary"></div>
          <UpcomingEvents />

          <MyCarouselEditorsPicks />
        </div>
        <NextSteps />
      </div>
    </div>
  );
}

export default Home;
