import React from "react";
import { toast } from "react-toastify";
import Loggito from "../../../utils/Loggito";
import "./Unsubscribe.css";
import { unsubscribe } from "../../../logic";
import { ServerError } from "errors";

function Unsubscribe() {
  const logger = new Loggito("Unsubscribe");

  const handleUnsubscribe = (event) => {
    event.preventDefault();
    unsubscribe(event.target.elements.email.value, (error) => {
      if (error) {
        if (error instanceof ServerError) {
          toast.error(error.message);
          logger.error(error.message);
        } else {
          toast.warn(error.message);
          logger.warn(error.message);
        }
        return;
      }
      event.target.reset();
      toast.success("You successfully unsubscribed");
    });
  };

  return (
    <div className="content">
      <div className="unsubscribe">
        <form action="" onSubmit={handleUnsubscribe}>
          <h2 className="h2">We are sad to see you go!</h2>
          <label htmlFor="unsubscribe">
            Enter email of subscriber to unsubscribe:
          </label>
          <input type="text" name="email" placeholder="Subscriber email" />
          <button className="button button--white-inverse" type="submit">
            Unsubscribe
          </button>
        </form>
      </div>
    </div>
  );
}

export default Unsubscribe;
