// ========== Imports ========== //

import "./CreateAssociate.css";

import { useState, useEffect } from "react";
import { createAssociateBusiness } from "../../../logic";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import kebabFileName from "../Helpers/kebabFileName";
import { useNavigate } from "react-router-dom";
// ========== Component ========== //

function CreateAssociate() {
  // ========== Hook consts ========== //

  const [title, setTitle] = useState("");
  const [route, setRoute] = useState("");
  const [state, setState] = useState(0);

  const navigate = useNavigate();
  // ========== other consts ========== //

  const logger = new Loggito("CreateAssociate");
  // let idEventIntroduced = "";

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Function to add/show CreateAssociate input fields ========== //

  const changeState = () => {
    if (state === 0) {
      setState(1);
    } else if (state === 1) {
      setState(0);
    }
  };
  /* 
  const allowedCharacters = /[^A-Za-z0-9]/g;

  const kebabFileName = (fileName) => {
    const fileNameKebab = fileName
      .replaceAll(allowedCharacters, "-")
      .toLowerCase();
    return fileNameKebab;
  }; */

  const handleChange = (title) => {
    setTitle(title);
    setRoute(kebabFileName(title));
  };

  // ========== Functions ========== //

  const handleCreateAssociate = () => {
    try {
      createAssociateBusiness(
        sessionStorage.token,
        title,
        route,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          navigate(`/admin/associatesbusiness/edit/${route}`);
          toast.success("associate created successfully");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const introduceAndClose = () => {
    handleCreateAssociate(title);
    changeState();
  };

  // ========== jsx ========== //

  return (
    <div className="block create-news">
      {state === 0 ? (
        <button className="button button--white-inverse" onClick={changeState}>
          Create Associate
        </button>
      ) : null}
      {state === 1 ? (
        <div className="p--medium p--bold">
          <label htmlFor="title">Associate Title:</label>

          <input
            name="title"
            type="text"
            value={title}
            onChange={(e) => handleChange(e.target.value)}
            className=""
            placeholder="This can be modified later..."
          />
          <button
            className="button button--white-inverse"
            onClick={introduceAndClose}
          >
            Create
          </button>
          <button
            className="button button--white-inverse"
            onClick={changeState}
          >
            Cancel
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default CreateAssociate;
