import React from "react";
import "./CardsKeynotesMobile.css";

function CardsKeynotesMobile(props) {
  return (
    <section className="cards-keynotes-mobile content">
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-1-navigating-the-educational-system_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            Navigating the Education System: Supporting Care Experienced Young
            People
          </h3>
          <p className="p--cards">
            Education is a vital part of a care experienced young person's
            journey, but it's not always easy for them to navigate. Many young
            people in care face unique challenges that can hinder their
            educational achievements. In this keynote, Mary-anne draws from her
            personal experience and psychology background to provide practical
            solutions for empowering care experienced young people in education.
            From addressing the impact of childhood trauma to building
            resilience, this keynote equips professionals to make a meaningful
            impact on the educational achievements and life outcomes of care
            experienced young people.
          </p>
        </div>
      </div>
      <div className="">
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-2-trauma-aware-care_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">Trauma Aware Care: Seeing Beyond Behaviour</h3>
          <p className="p--cards">
            This keynote equips professionals with practical strategies for
            providing trauma aware care to care experienced young people.
            Drawing from personal experience and psychology, Mary-anne shares
            insights into the impact of trauma on young people's behaviours and
            provides tools for recognising trauma responses and building
            resilience.
          </p>
          <p className="p--cards">
            By adopting a trauma aware approach, professionals can create a
            supportive environment that enables care experienced young people to
            thrive. This keynote inspires and empowers professionals to see
            beyond the behaviour, respond effectively to the needs of young
            people, and make a positive difference in their lives.
          </p>
        </div>
      </div>
      <div className="">
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-3-voices-heard_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            Voices Heard: Achieving Meaningful Engagement for Children and Young
            People in Care
          </h3>
          <p className="p--cards">
            In this keynote, Mary-anne explores the power of meaningful
            engagement and participation for children and young people in care.
            Through the lens of lived experience, examining the importance of
            creating opportunities for young people to have their voices heard
            and their perspectives valued. Attendees will leave with a deeper
            understanding of the impact of participation on the lives of young
            people in care and practical tools for creating a culture of
            meaningful engagement in their own work.
          </p>
        </div>
      </div>
      <div className="">
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-4-daring-to-dream_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            Daring to Dream: Aspirational Practice for Children and Young People
            in Care
          </h3>
          <p className="p--cards">
            This keynote inspires professionals to adopt an aspirational
            practice when working with children in care. Mary-anne shares
            practical strategies for creating a culture of aspiration that
            enables children to dream big and achieve their full potential. By
            focusing on the strengths and aspirations of each child,
            professionals can promote positive outcomes for children in care and
            challenge negative stereotypes. This keynote encourages
            professionals to dare to dream alongside the children they work with
            and support them in turning their dreams into reality.
          </p>
        </div>
      </div>
      <div className="">
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-5-building-bridges_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            Building Bridges: The Power of Connection and Relational Practice
            with Children in Care
          </h3>
          <p className="p--cards">
            In this keynote, Mary-anne emphasises the importance of connection
            and relational practice in working with children in care. By
            prioritising building meaningful connections with the children,
            professionals can create a safe and supportive environment that
            enables the child to thrive. Drawing on her personal experience and
            expertise in psychology, Mary-anne shares practical strategies for
            creating these connections, helping professionals to embrace the
            transformative power of relational practice. This keynote empowers
            professionals to build bridges that help to heal trauma, restore
            trust and foster a sense of belonging for children in care.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CardsKeynotesMobile;
