// ========== imports ========== //
import "./BlockPlansWorkingWith.css";
// ========== component ========== //
function BlockPlansWorkingWith() {
  // ========== jsx ========== //
  return (
    <section className="block container block-plans-working-with">
      <div className="block">
        <h2 className="h2">Mary-anne's Approach</h2>
        <div className="grid grid--1x2">
          <div className="plan">
            <div className="card card--secondary">
              <header className="card__header">
                <p className="plan__title">Aspirational Practice</p>
              </header>
              <div className="card__body">
                <p className="p--medium">
                  Working with the belief that every young person deserves the
                  opportunity to achieve their full potential and that this can
                  be achieved when the adults around them dare to dream about
                  the possibilities and potential of their future. Providing
                  practical guidance to support professionals to provide
                  informed options that reflect the diversity of the care
                  experienced community, acknowledging and celebrating their
                  unique qualities.
                </p>
              </div>
            </div>
          </div>
          <div className="plan">
            <div className="card card--secondary">
              <header className="card__header">
                <p className="plan__title">Collaborative, Unconditional Care</p>
              </header>
              <div className="card__body">
                <p className="p--medium">
                  Services support a transformative approach to care, one where
                  no one is excluded, where community and relationships are
                  valued at the forefront with a system that works to overcome
                  obstacles by forging stronger connections. We are here because
                  we care about children and young people and want them to
                  succeed; coming together in this understanding is how we will
                  make a difference. This learning is often guided by concepts
                  of healing, understanding trauma and working with the
                  principles of trauma- informed care.
                </p>
              </div>
            </div>
          </div>
          <div className="plan">
            <div className="card card--secondary">
              <header className="card__header">
                <p className="plan__title">Strengths-Based Practice</p>
              </header>
              <div className="card__body">
                <p className="p--medium">
                  Too often young people are faced with a list of, what can feel
                  like, what’s wrong with them, or what has gone wrong in their
                  lives. It can be difficult to look beyond the needs and build
                  the good. Building the good is a priority in Mary-annes
                  practice, working together to support children and young
                  people in developing and building those strengths.
                  Care-experienced children and young people deserve the chance
                  to exceed your expectations, and more importantly, they
                  deserve the support to exceed their own.
                </p>
              </div>
            </div>
          </div>
          <div className="plan">
            <div className="card card--secondary">
              <header className="card__header">
                <p className="plan__title">Child-Centered</p>
              </header>
              <div className="card__body">
                <p className="p--medium">
                  Mary-anne's work focuses on the 'whole child', with the
                  premise that we can improve outcomes when we better understand
                  young people, when we can connect to their history, journey
                  and their story and provide meaningful opportunities that work
                  to curate environments that unconditionally support healing
                  and growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid--1x3">
        <div className="plan">
          <div className="card card--secondary">
            <header className="card__header">
              <p className="plan__title">Core and Bespoke Packages</p>
            </header>
            <div className="card__body">
              <p className="p--medium">
                Mary-anne delivers both her core training and speaking services
                that explore a 'story through care' alongside offering bespoke
                training and speaking services to meet your desired needs.
              </p>
            </div>
          </div>
        </div>
        <div className="plan">
          <div className="card card--secondary">
            <header className="card__header">
              <p className="plan__title">Lived Experience and Theory</p>
            </header>
            <div className="card__body">
              <p className="p--medium">
                Mary-anne is passionate about using stories and lessons from her
                own time in care, balanced with relevant, up-to-date research
                and theories of Psychology to equip professionals with resources
                and tools to enhance practice.
              </p>
            </div>
          </div>
        </div>
        <div className="plan">
          <div className="card card--secondary">
            <header className="card__header">
              <p className="plan__title">Delivery</p>
            </header>
            <div className="card__body">
              <p className="p--medium">
                Flexible delivery, Mary-anne delivers the majority of her
                training online via Zoom or Teams. Mary-anne also delivers half
                and full day training experiences in person; please get in touch
                to discuss this in more detail.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlockPlansWorkingWith;
