// ================== Imports ================== //

import "./Search.css";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Loggito from "../../utils/Loggito";

import { schemaSearchGeneralForm } from "../../validators";
// ================== Component ================== //

function Search({ onQuery }) {
  // ================== Consts ================== //
  const logger = new Loggito("Search");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaSearchGeneralForm) });
  // ================== Functions ================== //

  // const handleSubmitForm = (event) => {
  const handleSubmitForm = (data) => {
    // event.preventDefault();
    // const query = event.target.query.value;
    const query = data.query;
    onQuery(query);
  };

  logger.info("return");

  // ================== jsx ================== //

  return (
    <div className="search-container">
      {/* <form onSubmit={handleSubmit}> */}
      <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
        {/* <div>
          <label className="p--medium label--select" htmlFor="category">
            Choose a category:
          </label>
          <select
            className="p--medium input--select"
            {...register("category")}
            name="category"
            // defaultValue={newsData.category}
            defaultValue={"All category"}
          >
            <option value="All categories">All categories</option>
            <option value="Category 1">Category 1</option>
            <option value="Category 2"> Category 2</option>
            <option value="Category 3"> Category 3</option>
            <option value="Category 4"> Category 4</option>
          </select>
          {errors.category && (
            <p className="input__error-message">{errors.category?.message}</p>
          )}
        </div> */}

        <div className="p--break">
          <label htmlFor="query">Search:</label>
          <input
            type="string"
            placeholder="Search news..."
            name="query"
            defaultValue={""}
            {...register("query", {})}
          />
          {errors.query && (
            <p className="input__error-message">{errors.query?.message}</p>
          )}
        </div>
        {/* <input type="text" name="query" placeholder="Search questions..." /> */}
        <button className="button button--white-inverse">search</button>
      </form>
    </div>
  );
}

export default Search;
