import React from "react";
import { Link } from "react-router-dom";
import { FaQuoteRight } from "react-icons/fa";
import ServicesButtons from "./ServicesButtons";

import "./Services.css";
import "./ServiceConsultancy.css";

function ServiceConsultancy() {
  return (
    <section className="services consultancy content">
      <div className="container">
        <header className="block__header">
          <h2 className="h2 consultancy-title">
            Mary-anne’s consultancy services provide bespoke support to
            organisations and professionals working with individuals who have
            experienced adversity.
          </h2>
          <p className="p--medium">
            Consultancy services include service design and implementation,
            policy, and practice development. With a focus on trauma-informed
            care, aspirational, strengths-based practice, and meaningful
            engagement, Mary-anne’s practice is rooted in the belief that every
            young person deserves the opportunity to achieve their full
            potential and the professionals around them deserve the tools to
            facilitate them thriving.
          </p>
          <p className="p--medium p--centered">
            Please complete the contact form with information regarding your
            project or required area of support.
          </p>
          <Link to="/contact" className="button button--white-inverse">
            Contact
          </Link>
        </header>

        <div className="focusAreas">
          <div className="grid grid--1x2">
            <div className="consultancy-flex-container">
              <div className="">
                <h3 className="h3">Example Areas for Focus:</h3>
                <ul className="p--medium">
                  <li>Care Leaver Provisions and Services</li>
                  <li>Care Experienced/Leaver Service Design and Review</li>
                  <li>Care Leavers in Employment</li>
                  <li>Care Experienced Educational Achievements</li>
                  <li>Strengths-Based Care</li>
                  <li>Participation and Engagement</li>
                  <li>
                    Language guidance (eg. Children's homes guides and practice
                    standards)
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="consultancy-flex-container">
                <img
                  className="consultancy-image"
                  // src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/mary_img2.jpeg"
                  src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_consultancy.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="link-container">
          <Link to="/contact" className="button button--white-inverse">
            Find out more
          </Link>
        </div>
        <div className="testimonials">
          <div className="testimonial">
            <img
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/nhs-logo.png"
              alt="Gingernut Training"
            />
            <p className="title">NHS</p>
            <p className="summary">
              <strong>Project: </strong>
              Design and delivery of a webinar and toolkit, supporting care
              leavers in employment.
            </p>
            <div className="quote-icon-container">
              <FaQuoteRight className="icon" />
            </div>
            <p className="testimony p--medium">
              "Working with Mary has been an absolute pleasure. She is
              inspirational and her passion shines through in everything she
              does. Her lived experience is so powerful and the positive
              feedback on the webinars and toolkit she supported the NHS with
              has been phenomenal."
            </p>
            <p className="person">
              <strong>Lucy Hunte,</strong> National Programme Manager –
              Apprenticeships
            </p>
          </div>
          <div className="testimonial">
            <img
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo_gingernut.png"
              alt="Gingernut Training"
            />
            <p className="title">Gingernut Training</p>
            <p className="summary">
              <strong>Project: </strong>
              Consultancy to support funding bid, supporting care leavers in
              employment.
            </p>
            <div className="quote-icon-container">
              <FaQuoteRight className="icon" />
            </div>
            <p className="testimony p--medium">
              "Mary-Anne is an expert in her field. I would have absolutely no
              hesitation in recommending her. Whatever you were planning on
              doing. Double it. You wont regret it."
            </p>
            <p className="person">
              <strong>Phil Warnock,</strong> Founder at Gingernut Training
            </p>
          </div>
          <div className="testimonial">
            <img
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo_rees.png"
              alt="Gingernut Training"
            />
            <p className="title">The Rees Foundation</p>
            <p className="summary">
              <strong>Project: </strong>
              Service design of a task-centred model and trauma-informed whole
              team training.
            </p>
            <div className="quote-icon-container">
              <FaQuoteRight className="icon" />
            </div>
            <p className="testimony p--medium">
              "Mary-Anne inspired and challenged every member of our
              organisation through a bespoke training experience that she
              crafted for us. Part of this training included design and
              development of a Task- Centered Model for working with care
              experienced adults on the phone. Mary-Anne created and delivered
              this with understanding and care. Without hesitation I recommend"
            </p>
            <p className="person">
              <strong>Luke Chapman,</strong> National Service Development
              Manager at The Rees Foundation
            </p>
          </div>
        </div>
        <div className="link-container">
          <Link to="/contact" className="button button--white-inverse">
            Enquire now
          </Link>
        </div>
        <ServicesButtons />
      </div>
    </section>
  );
}

export default ServiceConsultancy;
