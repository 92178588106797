import { ServerError, ClientError } from "errors";
import {
  validateNumber,
  validateCallback,
  validateToken,
  validateText,
  validateFile,
  validateStatus,
  validateString,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateAssociateBusiness(
  token,
  action,
  route,
  newsDataToUpdate,
  callback
) {
  const { status, title, newRoute, rank, link, mainImage } = newsDataToUpdate;

  //====== validation ======//
  validateToken(token);
  validateText(action, "action");
  validateText(route, "route");
  validateCallback(callback);

  if (action === "details") {
    validateText(title, "title");
    validateText(newRoute, "new route");
    validateNumber(rank, "position");
    validateString(link, "link");
    validateStatus(status);
  }

  if (action === "mainImage") {
    validateFile(mainImage, "main image");
  }

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/business/associates/${route}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  if (action === "details")
    xhr.send(
      JSON.stringify({
        action: action,
        title: title,
        newRoute: newRoute,
        status: status,
        rank: rank,
        link: link,
      })
    );
  else if (action === "mainImage")
    xhr.send(
      JSON.stringify({
        action: action,
        mainImage: mainImage,
      })
    );
}

export default updateAssociateBusiness;
