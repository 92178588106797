// ========== imports ========== //
import "../Associates/AddAssociate.css";

import Loggito from "../../../utils/Loggito";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
// to install $npm install @hookform/resolvers joi

import { ServerError } from "errors";
import { getUpcomingEventsItem, updateUpcomingEvent } from "../../../logic";
import { schemaUpcomingEventsForm } from "../../../validators";

import { Spinner } from "../../";
import kebabFileName from "../Helpers/kebabFileName";

// ========== Page ========== //

function UpcomingEvents() {
  // ========== Hook consts ========== //

  const [eventData, setEventData] = useState();

  const params = useParams();
  const navigate = useNavigate();

  let route = params.route;

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getUpcomingEventsItem(
        sessionStorage.token,
        route,
        (error, eventDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/upcomingevents");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/upcomingevents");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setEventData(eventDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/upcomingevents");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);
  // ========== other consts ========== //

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaUpcomingEventsForm) });

  const logger = new Loggito("AddAssociate");
  // ========== useEffects ========== /

  const handleUpdateUpcomingEvents = (data) => {
    const eventDataToUpdate = {};
    eventDataToUpdate.title = data.title;
    eventDataToUpdate.newRoute = kebabFileName(data.title);
    eventDataToUpdate.date = data.date;
    eventDataToUpdate.finishTime = data.finishTime;
    eventDataToUpdate.host = data.host;
    eventDataToUpdate.rank = data.rank;
    eventDataToUpdate.link = data.link;

    try {
      updateUpcomingEvent(
        sessionStorage.token,
        route,
        eventDataToUpdate,

        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }

          const newRoute = `/admin/upcomingevents/edit/${kebabFileName(
            data.title
          )}`;
          navigate(newRoute);
          toast.success("Event updated successfully");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const handleFormSubmit = (data) => {
    handleUpdateUpcomingEvents(data);
  };

  // ========== jsx ========== //
  if (eventData) {
    return (
      <div className="add-associate">
        <div className="content">
          <h2 className="h2">Add Upcoming Event</h2>
          <h3 className="h3">Please complete the details below:</h3>
          <form
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            className="add-associate-form"
            noValidate={true}
          >
            <div>
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                placeholder=""
                name="title"
                defaultValue={eventData.title}
                {...register("title", {})}
              />
              {errors.title && (
                <p className="input__error-message">{errors.title?.message}</p>
              )}
            </div>
            <div>
              <label className="label--select" htmlFor="date">
                {`Choose a date: (Current Date is ${new Date(
                  eventData.date
                ).toUTCString()} 
                  at ${eventData.date.slice(11, 16)}) `}
              </label>
              <input
                type="datetime-local"
                placeholder=""
                {...register("date")}
              />
              {errors.date && (
                <p className="input__error-message">{errors.date?.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="finishTime">Finish time:</label>
              <input
                type="time"
                placeholder=""
                name="finishTime"
                defaultValue={eventData.finishTime}
                {...register("finishTime", {})}
              />
              {errors.finishTime && (
                <p className="input__error-message">
                  {errors.finishTime?.message}
                </p>
              )}
            </div>
            <div>
              <label htmlFor="rank">Position:</label>
              <input
                type="number"
                placeholder=""
                name="rank"
                defaultValue={eventData.rank}
                {...register("rank", {})}
              />
              {errors.rank && (
                <p className="input__error-message">{errors.rank?.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="host">Host:</label>
              <input
                type="text"
                placeholder=""
                name="host"
                defaultValue={eventData.host}
                {...register("host", {})}
              />
              {errors.host && (
                <p className="input__error-message">{errors.host?.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="link">URL link:</label>
              <input
                type="text"
                placeholder=""
                name="link"
                defaultValue={eventData.link}
                {...register("link", {})}
              />
              {errors.link && (
                <p className="input__error-message">{errors.link?.message}</p>
              )}
            </div>

            <button type="submit" className="button button--white-inverse">
              Save
            </button>
          </form>
        </div>
      </div>
    );
  } else return <Spinner />;
}

export default UpcomingEvents;
