import React from "react";
import "./AFit.css";
import { Link } from "react-router-dom";
import { CTA } from "../";

function AFit() {
  return (
    <div className="afit">
      <div className="content">
        <img
          className="afit-banner afit-banner_d"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/afit-banner-long2.jpg"
          alt=""
        />
        <img
          className="afit-banner afit-banner_m"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/afit-banner_m.jpg"
          alt=""
        />
        <div className="block1">
          <div className="text-wrap-image__img">
            <img
              className="afit-logo"
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-afit-text.jpg"
              alt=""
            />
          </div>
          <h3 className="h3">
            OUTCOME: After taking the course you will understand:
          </h3>
          <ul className="p--medium">
            <li>Why pupils’ relationships affect school performance</li>
            <li>
              What need is unsettling child behaviour trying to communicate
            </li>
            <li>
              How you can intervene to translate needs into worthwhile help.
            </li>
            <li>
              Why and how AFIT helps you record and print results that are
              correlated with SDQs
            </li>
            <li>
              How AFIT can be used as evidence when EHCP help is seen as needed
            </li>
          </ul>
          <p className="p--medium p--bold">
            And you will have your own AFIT for you and up to 4 colleagues to
            use for 6 months free of charge.
          </p>
          <p className="p--medium">
            Click{" "}
            <a
              href="https://www.coursewedo.com/reach2teach-practice-based-evidence"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            to see what other teachers and professional think of AFIT
          </p>
        </div>
        <div className="block2">
          <h3 className="h3">
            SCHOOL/PUPIL BENEFIT: Why we believe Reach2Teach AFIT is the{" "}
            <strong>best</strong> online support for all children to be settled
            to learn and flourish at school
          </h3>
          <ul>
            <li>
              It has been created by Worth Publishing whose attachment aware and
              trauma informed books by authors such as Dan Hughes, Louise
              Bomber, Heather Geddes, Margot Sunderland, Marie Delaney and many
              others have created a huge evidence base of successful school
              interventions since 2005.
            </li>
            <li>
              AND only Reach2Teach AFIT can do the following:- <br />
              <ol>
                <li>
                  Only on AFIT can all relevant information on pupil home
                  background and school intervention strategies that are AFIT
                  recommended and tried, be recorded on one central record
                  called the Pupil Dashboard
                </li>
                <li>
                  Only on AFIT do the initial assessment and all subsequent
                  intervention assessments correlate with SDQs
                </li>
                <li>
                  Only AFIT can provide schools with evidence and shared access
                  for a social worker, educational psychologist or any other
                  external professional within GDP regulations
                </li>
                <li>
                  Only from AFIT can school personnel share, print and edit
                  evidence-based reports essential for a spectrum from PEP
                  meetings to Court cases.
                </li>
                <li>
                  Only AFIT can ensure portability of the Pupil Dashboard within
                  GDPR should a pupil move school or area.
                </li>
              </ol>
            </li>
          </ul>
          <p className="p--medium">
            Cost £175 plus VAT . Includes 6 months use of the Basic Reach2Teach
            package
          </p>
          <p className="p--medium">
            Contact{" "}
            <strong>
              <Link className="link" to="/contact">
                Mary-anne{" "}
              </Link>
            </strong>
            to register
          </p>
          <CTA text="Contact" route="/contact" />
        </div>
      </div>
    </div>
  );
}

export default AFit;
