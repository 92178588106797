import { ServerError, ClientError } from "errors";
import { validateToken, validateCallback } from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function getUpcomingEventsClient(callback) {
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 200) {
      const upcomingEventsArray = xhr.responseText;

      const upcomingEventsArrayParsed = JSON.parse(upcomingEventsArray);
      callback(null, upcomingEventsArrayParsed);
    }
  };

  xhr.open("GET", `${API_URL}/upcomingevents`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send();
}

export default getUpcomingEventsClient;
