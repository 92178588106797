import Joi from "joi";

const schemaAddAssociateBusinessForm = Joi.object({
  title: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid title",
    "string.empty": "please enter a title",
  }),
  status: Joi.string()
    .trim()
    .allow("")
    .valid("", "draft", "published", "inactive")
    .min(1)
    .messages({
      "string.base": "please select a valid status",
      "string.empty": "please select a status",
    }),
  rank: Joi.number().min(1).messages({
    "number.base": "please enter a valid rank",
    "number.empty": "please enter a rank",
  }),
  link: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid URL",
    "string.empty": "please enter a URL",
  }),
});

export default schemaAddAssociateBusinessForm;
