import "./NewsView.css";

import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ServerError } from "errors";
import Loggito from "../../../utils/Loggito";
import { Spinner } from "../..";
import { getNewsItem } from "../../../logic";
import parse from "html-react-parser";

import ReactPlayer from "react-player/youtube";

function NewsView() {
  const [newsData, setNewsData] = useState();
  const params = useParams();
  const navigate = useNavigate();

  let route = params.route;

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getNewsItem(sessionStorage.token, route, (error, newsDataRetrieved) => {
        if (error) {
          if (error instanceof ServerError) {
            navigate("/admin/newstable");
            toast.error(error.message);
            logger.error(error.message);
          } else {
            navigate("/admin/newstable");
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        setNewsData(newsDataRetrieved);
      });
    } catch (error) {
      navigate("/admin/newstable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  const logger = new Loggito("NewsView");

  if (newsData) {
    return (
      <div className="news-view">
        <h2 className="h2">{newsData.title}</h2>
        {newsData.video ? (
          <div className="player-wrapper">
            <ReactPlayer className="react-player" url={`${newsData.video}`} />
          </div>
        ) : null}
        <div>{parse(newsData.newsHtml)}</div>
        {newsData.file1 ? (
          <a href={newsData.file1.url}>{newsData.file1.title}</a>
        ) : null}
        {newsData.file2 ? (
          <a href={newsData.file2.url}>{newsData.file2.title}</a>
        ) : null}
        {newsData.file3 ? (
          <a href={newsData.file3.url}>{newsData.file3.title}</a>
        ) : null}
      </div>
    );
  } else return <Spinner />;
}

export default NewsView;
