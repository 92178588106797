import { useLocation } from "react-router-dom";

import "./Titles.css";

function Titles(props) {
  const location = useLocation();

  return (
    <div className="title--main">
      {location.pathname === "/" ? (
        <div>
          <h1 className="h1">Mary-anne Hodd</h1>
          <p className="title-caption">
            {/* Therapeutic Development to Support Children and Young People
            Thriving<br></br> */}
            {/* Training, Consultancy and Speaking Services, <br></br>Combining
            Lived Experience and Psychological Perspectives */}
            {/* <span style={{ fontSize: "11px" }}> */}
            Professional Development Services Combining Lived and Psychological
            Insights
            {/* </span> */}
          </p>
        </div>
      ) : null}
      {location.pathname === "/home" ? (
        <div>
          <h1 className="h1">Mary-anne Hodd</h1>
          <p className="title-caption">
            {/* Therapeutic Development to Support Children and Young People Thriving */}
            Professional Development Services Combining Lived and Psychological
            Insights
          </p>
        </div>
      ) : null}
      {location.pathname === "/about" ? (
        <h1 className="h1">About Mary-anne</h1>
      ) : null}
      {location.pathname === "/afit" ? <h1 className="h1">AFIT</h1> : null}
      {location.pathname === "/services" ? (
        <h1 className="h1">Services</h1>
      ) : null}
      {location.pathname === "/training" ? (
        <h1 className="h1">Training</h1>
      ) : null}
      {location.pathname === "/keynotes" ? (
        <h1 className="h1">Keynotes</h1>
      ) : null}
      {location.pathname === "/consultancy" ? (
        <h1 className="h1">Consultancy</h1>
      ) : null}
      {location.pathname.slice(0, 5) === "/blog" ? (
        <h1 className="h1">Blog</h1>
      ) : null}
      {location.pathname === "/the-guarantor-scheme" ? (
        <h1 className="h1">The Guarantor Scheme</h1>
      ) : null}
      {location.pathname === "/associates" ? (
        <h1 className="h1">Associates</h1>
      ) : null}
      {location.pathname === "/associates2" ? (
        <h1 className="h1">Associates</h1>
      ) : null}
      {location.pathname === "/contact" ? (
        <h1 className="h1">Contact</h1>
      ) : null}
      {location.pathname === "/unsubscribe" ? (
        <h1 className="h1">Manage Subscription</h1>
      ) : null}
      {location.pathname === "/privacypolicy" ? (
        <h1 className="h1">Privacy Policy</h1>
      ) : null}
      {location.pathname === "/cookiespolicy" ? (
        <h1 className="h1">Cookies Policy</h1>
      ) : null}
      {location.pathname === "/sitemap" ? (
        <h1 className="h1">Site Map</h1>
      ) : null}
    </div>
  );
}

export default Titles;
