// ========== Imports ========== //

import "../Associates/AssociatesTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../";
import { CreateUpcomingEvent, DeleteUpcomingEvent } from "../";
import { getUpcomingEvents } from "../../../logic";
import { Pagination } from "../../";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("UpcomingEventsTable");

// Receives the eventId and return the table

function UpcomingEventsTable() {
  // ========== Hook consts ========== //

  const [eventsData, setEventsData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 12;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return eventsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  // ========== other consts ========== //

  function rowTable(event, index) {
    // ========== JSX ========== //

    return (
      <tr key={event.route}>
        <td>{event.title}</td>
        <td>{new Date(event.date).toUTCString()}</td>
        <td>{event.rank}</td>
        {/* <td>{event.dateSubscribed}</td> */}
        <td className="buttons-td">
          {
            <>
              {/* <Link
                to={`/admin/eventsbusiness/view/${event.route}`}
                className="link"
              >
                View
              </Link>{" "}
              / */}
              <Link
                to={`/admin/upcomingevents/edit/${event.route}`}
                className="link"
              >
                 Edit
              </Link>{" "}
              /
              <DeleteUpcomingEvent event={event} />
            </>
            /*
          <EditButton subscriberId={subscriber.id} subscriberKey={subscriberKeyfound} />
          <Deletesubscriber subscriberId={subscriber.id} subscriberKey={subscriberKeyfound} text={"X"} /> */
          }
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getUpcomingEvents(sessionStorage.token, (error, eventsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        // setEventsData(eventsArray.map(rowTable));
        setEventsData(eventsArray.map(rowTable));

        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== JSX ========== //

  if (eventsData !== "") {
    return (
      <div className="associates-table">
        <h1>Manage Upcoming Events</h1>
        <CreateUpcomingEvent />
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Event</th>
              <th>Date</th>
              <th>Position</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* <tbody>{eventsData ? eventsData : null}</tbody> */}
          <tbody>{currentTableData ? currentTableData : null}</tbody>
        </table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={eventsData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default UpcomingEventsTable;
