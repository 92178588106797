// ========== imports ========== //
import "./Audience.css";
import { BsCheckLg } from "react-icons/bs";
// ========== component ========== //
function Audience() {
  // ========== jsx ========== //
  return (
    <section className="block container audience" id="audience">
      <div className="plan">
        <div className="card card--secondary">
          <header className="card__header">
            <p className="plan__title">Audience</p>
          </header>
          <div className="card__body">
            <p className="p--medium">
              Mary-anne's work is for anyone who works with and around children
              and young people in care, children and young people who have
              experienced adversity. These include:
            </p>
            <ul className="list list--tick">
              <li className="list__item">
                <BsCheckLg className="check" />
                Social Workers
              </li>
              <li className="list__item">
                <BsCheckLg className="check" />
                Foster Carers
              </li>
              <li className="list__item">
                <BsCheckLg className="check" />
                Leaving Care Practitioners
              </li>
              <li className="list__item">
                <BsCheckLg className="check" />
                Children's Services Teams and Leaders
              </li>
              <li className="list__item">
                <BsCheckLg className="check" />
                Teachers and virtual schools
              </li>
              <li className="list__item">
                <BsCheckLg className="check" />
                Residential Care Practitioners
              </li>
              <li className="list__item">
                <BsCheckLg className="check" />
                Corporate Parents
              </li>
              <li className="list__item">
                <BsCheckLg className="check" />
                Supported Accommodation Teams
              </li>
              + More
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Audience;
