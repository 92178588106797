// ========== imports ========== //
import "./Blog.css";
import React, { useState, useEffect, useMemo } from "react";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import withContext from "../../../utils/withContext";

import { ServerError } from "errors";
import { Link } from "react-router-dom";
import { NextSteps, SearchGeneral, MyCarouselMentions, Spinner } from "../..";
import { getNewsClient, searchNewsClient } from "../../../logic";

// ========== Page ========== //

function Blog() {
  // ========== Hook consts ========== //
  const [newsData, setNewsData] = useState("");

  const [query, setQuery] = useState();

  useEffect(() => {
    loadNews();
  }, []);

  useEffect(() => {
    loadNews();
  }, [query]);

  const loadNews = () => {
    try {
      if (query === undefined)
        return getNewsClient((error, newsArray) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          setNewsData(newsArray);
          setCurrentPage(1);
        });
      else
        return searchNewsClient(query, (error, newsArray) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          setNewsData(newsArray);
          setCurrentPage(1);
        });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const handleSearch = (query) => {
    setQuery(query);
  };
  // ========== other consts ========== //

  const logger = new Loggito("Articles");

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return newsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, newsData]);
  // ========== jsx ========== //

  if (newsData) {
    return (
      <div className="">
        <div className="content blog">
          <MyCarouselMentions />
          {currentTableData.length >= 2 || query ? (
            <>
              <h2 className="h2 p--centered intro">
                Blog Posts, Videos and New Content
              </h2>
              <p className="p--medium p--centered">
                In this blog, you'll find Mary-anne's personal entries including
                new articles and original video content.
              </p>
              <p className="p--medium p--centered">
                Please feel free to leave a comment or subscribe to keep
                up-to-date with new releases.
              </p>
              <div className="block">
                <SearchGeneral onQuery={handleSearch} />
                {currentTableData.length === 0 ? (
                  <p className="p--medium p--bold p--centered no-search-results">
                    Sorry, your search returned no relevant results
                  </p>
                ) : null}
                {currentTableData.length !== 0 ? (
                  <>
                    <h2 className="h2 p--centered">Newest Releases</h2>
                    <ul className="articles list grid grid--1x2">
                      {currentTableData.slice(0, 2).map((article) => {
                        return (
                          <li className="article" key={article.title}>
                            <img
                              className="article__image "
                              src={article.mainImage.fileUrl}
                              alt=""
                            />
                            <Link
                              to={`/blog/${article.route}`}
                              className="button button--white read-more read-more--large"
                            >
                              Read more...
                            </Link>
                            <Link
                              to={`/blog/${article.route}`}
                              className="article__link"
                            >
                              <p className="p--medium p--bold p--article">
                                {article.title}
                              </p>
                            </Link>
                            <p className="p--medium">{article.excerpt} </p>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : null}
              </div>
            </>
          ) : null}

          {currentTableData.length > 2 ? (
            <div className="block">
              <h2 className="h2 p--centered">
                Other Articles and Video Entries
              </h2>
              <ul className="articles list articles-flex-container">
                {currentTableData.slice(2).map((article) => {
                  return (
                    <li className="article" key={article.title}>
                      <img
                        className="article__image article__image--small "
                        src={article.mainImage.fileUrl}
                        alt=""
                      />
                      <Link
                        to={`/blog/${article.route}`}
                        className="button button--white read-more read-more--small"
                      >
                        Read more...
                      </Link>
                      <Link
                        to={`/blog/${article.route}`}
                        className="article__link"
                      >
                        <p className="p--medium p--bold p--article">
                          {article.title}
                        </p>
                      </Link>
                      <p className="p--medium">{article.excerpt} </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}

          <NextSteps />
        </div>
      </div>
    );
  } else
    return (
      <div className="content">
        <Spinner />
      </div>
    );
}

export default withContext(Blog);
