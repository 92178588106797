import { ServerError, ClientError, FormatError } from "errors";
import {
  validateCallback,
  validateToken,
  validateText,
  validateDate,
  validateString,
  validateNumber,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateUpcomingEvent(
  token,
  route,
  upcomingEventsDataToUpdate,
  callback
) {
  const { title, newRoute, rank, link, host, date, finishTime } =
    upcomingEventsDataToUpdate;

  //====== validation ======//
  validateToken(token);
  validateCallback(callback);

  validateText(title, "title");
  validateText(newRoute, "new route");
  if (!!date) validateDate(date);
  validateString(host, "host");
  validateString(link, "link");
  validateString(finishTime, "finish time");
  validateNumber(rank, "position");

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/upcomingevents/${route}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    JSON.stringify({
      title: title,
      newRoute: newRoute,
      date: date,
      finishTime: finishTime,
      rank: rank,
      host: host,
      link: link,
    })
  );
}

export default updateUpcomingEvent;
