import { ServerError, ClientError } from "errors";
import { validateToken, validateCallback } from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function getAssociates(token, callback) {
  validateToken(token);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 200) {
      const associatesArray = xhr.responseText;

      const associatesArrayParsed = JSON.parse(associatesArray);
      callback(null, associatesArrayParsed);
    }
  };

  xhr.open("GET", `${API_URL}/associates/admin`);

  xhr.setRequestHeader("Content-type", "application/json");

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.send();
}

export default getAssociates;
