import "./RichTextEditorTiny.css";

import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
// npm install react-html-parser --legacy-peer-deps
// import ReactHtmlParser from "react-html-parser";
// conflict with newer version of react - can use this as a fix or an older version of react or.. npm i html-react-parser (older version)
// Final solution: install html-react-parser instead of react-html-parser and install with the lagacy-peers-deps
// npm install html-react-parser --legacy-peer-deps

import { HiChevronRight } from "react-icons/hi";
import parse from "html-react-parser";

function RichTextEditorTiny({ onSaveContent, startingValue, environment }) {
  const [displayContent, setDisplayContent] = useState(null);
  const [isActiveP1, setIsActiveP1] = useState(false);
  const editorRef = useRef(null);
  const log = (e) => {
    // TODO: I added the preventDefault but doesn't seem to do much
    e.preventDefault();
    if (editorRef.current) {
      setDisplayContent(editorRef.current.getContent());
      onSaveContent && onSaveContent(editorRef.current.getContent());
    }
  };

  // TODO: organise toolbar and remove unnecessary options
  return (
    <div className="tiny">
      <div className="block">
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          // onChange={log}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={
            startingValue
              ? startingValue
              : "<p>This is the initial content of the editor.</p>"
          }
          init={{
            height: 500,
            // menubar: false,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "preview",
              "help",
              "wordcount",
              // NEW
              "media",
              "image code",
            ],
            promotion: false,
            toolbar1:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent",
            toolbar2:
              "removeformat | help | media image advlist autolink lists link harmap searchreplacefullscreen table | preview wordcount // NEWmedia | print | code",
            // Unused options: "anchor insertdatetime visualblocks code "

            /* toolbar_groups: {
                formatgroup: { 
                    icon: 'format',
                    tooltip: 'Formatting',
                    items: 'bold italic underline strikethrough | forecolor backcolor | superscript subscript | removeformat'
                },
                paragraphgroup: {
                    icon: 'paragraph',
                    tooltip: 'Paragraph format',
                    items: 'h1 h2 h3 | bullist numlist | alignleft aligncenter alignright | indent outdent'
                },
                insertgroup: {
                    icon: 'plus',
                    tooltip: 'Insert',
                    items: 'link image emoticons charmap hr'
                }
            }, */
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            image_title: true,
            automatic_uploads: true,
            file_picker_types: "image",
            /* file_picker_callback: (callback, value, meta) => {
                // Provide file and text for the link dialog
                if (meta.filetype == "file") {
                    callback("mypage.html", { text: "My text" });
                }
                
                // Provide image and alt text for the image dialog
                if (meta.filetype == "image") {
                    callback("myimage.jpg", { alt: "My alt text" });
                }
                
                // Provide alternative source and posted for the media dialog
                if (meta.filetype == "media") {
                    callback("movie.mp4", {
                        source2: "alt.ogg",
                        poster: "image.jpg",
                    });
                }
            }, */
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.onchange = function () {
                var file = this.files[0];

                var reader = new FileReader();
                reader.onload = function () {
                  var id = "blobid" + new Date().getTime();
                  var blobCache = editorRef.current.editorUpload.blobCache;
                  var base64 = reader.result.split(",")[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);

                  /* call the callback and populate the Title field with the file name */
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };
              input.click();
            },
          }}
        />
        <button
          className="button button--white-inverse"
          type="button"
          onClick={log}
        >
          Save editor content
        </button>
      </div>
      <div className="block tiny__display">
        <p>
          Save editor content to see a preview below (content must be saved
          before sending - videos and files uploaded will not be shown in this
          preview):
        </p>

        <div
          className={`collapsible ${
            isActiveP1 ? "collapsible--expanded" : null
          }`}
        >
          <header className="collapsible__header">
            <h3 className="h3 collapsible__heading edit-event__section-title">
              Preview saved content:
            </h3>
          </header>
          <div
            className="react-icon__container"
            onClick={() => setIsActiveP1(!isActiveP1)}
          >
            <HiChevronRight
              className={`react-icon__icon--small react-icon__icon--chevronright ${
                isActiveP1 && "react-icon__icon--active"
              }`}
            />
          </div>
          <div className="collapsible__content">
            <div
              className={
                environment === "news" ? "tiny__display-content" : null
              }
            >
              {!displayContent && startingValue ? parse(startingValue) : null}
              {displayContent ? parse(displayContent) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RichTextEditorTiny;
