import React from "react";
import { Link } from "react-router-dom";

import "./ServicesButtons.css";

function ServicesButtons(props) {
  return (
    <div className="services-buttons-container">
      <Link to="/training">
        <img
          className="services-button"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_training.png"
          alt=""
        />
        <p className="services-link--text">Training</p>
      </Link>
      <Link to="/keynotes">
        <img
          className="services-button"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_keynotes.png"
          alt=""
        />
        <p className="services-link--text">Keynotes</p>
      </Link>
      <Link to="/consultancy">
        <img
          className="services-button"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_mh.png"
          alt=""
        />
        <p className="services-link--text">Consultancy</p>
      </Link>
      <Link to="/the-guarantor-scheme">
        <img
          className="services-button"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_guarantor.png"
          alt=""
        />
        <p className="services-link--text">
          Guarantor
          <br />
          Scheme
        </p>
      </Link>
    </div>
  );
}

export default ServicesButtons;
