import React from "react";
import "./ThreeCards.css";

function ThreeCards() {
  return (
    <div>
      <div className="three-cards--details">
        <div className="grid grid--1x3">
          <div className="card">
            <div className="header">Header</div>
            <div className="content">Content</div>
          </div>
          <div className="card">
            <div className="header">Header</div>
            <div className="content">Content</div>
          </div>
          <div className="card">
            <div className="header">Header</div>
            <div className="content">Content</div>
          </div>
        </div>
      </div>

      <div style={{ padding: "60px" }}></div>

      <div className="three-cards">
        <div className="grid grid--1x3">
          <div className="card">1</div>
          <div className="card">2</div>
          <div className="card">3</div>
        </div>
      </div>
    </div>
  );
}

export default ThreeCards;
