import { ServerError, ClientError, FormatError } from "errors";
import {
  validateCallback,
  validateToken,
  validateText,
  validateObjectIdString,
  validateCommentStatus,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function updateCommentStatus(token, commentId, status, callback) {
  //====== validation ======//
  validateToken(token);
  validateObjectIdString(commentId, "comment ID");
  validateCommentStatus(status);
  validateCallback(callback);

  const xhr = new XMLHttpRequest();

  //response

  xhr.onload = function () {
    const status = xhr.status;

    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 204) {
      callback(null);
    }
  };
  // XMLHttprequest

  xhr.open("PATCH", `${API_URL}/comments/${commentId}`);

  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.setRequestHeader("Content-type", "application/json");

  xhr.send(
    JSON.stringify({
      status,
    })
  );
}

export default updateCommentStatus;
