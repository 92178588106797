import React from "react";
import { Link } from "react-router-dom";
import "./CTA.css";
function CTA({ text, route }) {
  return (
    <div className="CTA-container">
      <Link to={route} className="button button--white-inverse link">
        {text}
      </Link>
    </div>
  );
}

export default CTA;
