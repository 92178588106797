import "./FooterSubscribeForm.css";

import { ServerError } from "errors";

import { useRef } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import Loggito from "../../utils/Loggito";
import { schemaSubscribeForm } from "../../validators";
import { addSubscriber } from "../../logic";

import parse from "html-react-parser";

function FooterSubscribeForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaSubscribeForm) });

  const logger = new Loggito("Footer Subscribe Form");

  const subscribeForm = useRef();

  const handleFormSubmit = (data) => {
    // event.preventDefault();
    try {
      addSubscriber(
        data.firstName.trim(),
        data.surname.trim(),
        data.email.trim(),
        (error) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else if (
              error.message.slice(0, 32) === "error 409: subscriber with email"
            ) {
              toast.success("Subscribed successfully 😀");
              logger.warn(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          subscribeForm.current.reset();
          logger.debug("subscriber added");
          toast.success("Subscribed successfully 😀");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  return (
    <div className="footer-subscribe-form">
      <div className="grid grid--1x2">
        <div className="">
          <h2 className="h2 h2--subscribe">
            Subscribe for articles, free resources & news
          </h2>
          <form
            ref={subscribeForm}
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            className="contact-form"
            noValidate={true}
          >
            <div className="form__fields">
              <div className="form__field">
                <label htmlFor="firstName">First name:</label>
                <input
                  type="text"
                  placeholder=""
                  name="firstName"
                  {...register("firstName", {})}
                />
                {errors.firstName && (
                  <p className="input__error-message">
                    {errors.firstName?.message}
                  </p>
                )}
              </div>
              <div className="form__field">
                <label htmlFor="surname">Surname:</label>
                <input
                  type="text"
                  placeholder=""
                  name="surname"
                  {...register("surname", {})}
                />
                {errors.surname && (
                  <p className="input__error-message">
                    {errors.surname?.message}
                  </p>
                )}
              </div>
              <div className="form__field">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  placeholder=""
                  name="email"
                  {...register("email", {})}
                />
                {errors.email && (
                  <p className="input__error-message">
                    {errors.email?.message}
                  </p>
                )}
              </div>
            </div>
            <button type="submit" className="button button--white-inverse">
              Submit
            </button>
          </form>
        </div>
        <div className="feed-container">
          <div className="ig-feed--side">
            {parse(
              '<!-- LightWidget WIDGET --><script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="https://cdn.lightwidget.com/widgets/7a09260bd5a155a2a35a1eb0f5dc0394.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;height: 330px;"></iframe>'
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterSubscribeForm;
