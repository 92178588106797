import "./Footer.css";

import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <div className="footer">
      <p className="p--small p--centered">
        © Vital Voice Ltd, All rights reserved<span> | </span>
        <Link className="footer__link" to={"/privacypolicy"}>
          Privacy Policy
        </Link>
        <span> | </span>
        <Link className="footer__link" to={"/sitemap"}>
          Sitemap
        </Link>
      </p>
      <p className="p--small p--centered">
        By using this site, you agree we can set and use cookies.{" "}
        <Link className="footer__link" to={"/cookiespolicy"}>
          View cookies policy
        </Link>
      </p>
    </div>
  );
}

export default Footer;
