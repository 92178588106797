import React from "react";
import { Link } from "react-router-dom";
import CardsTrainingDesktop from "./CardsTrainingDesktop";
import CardsTrainingMobile from "./CardsTrainingMobile";
import ServicesButtons from "./ServicesButtons";
import { HashLink } from "react-router-hash-link";

import "./Services.css";
import "./ServiceTraining.css";

function ServiceTraining() {
  return (
    <section className="services training content">
      <div className="block container">
        <header className="block__header">
          <h2 className="h2">
            Core and bespoke training to equip professionals with the resources
            to support children and young people thriving.
          </h2>
          <p>
            Specialising in children and young people who have encountered early
            adversity, Mary-anne's training services offer invaluable insights
            and practical strategies for professionals supporting young people
            who are care experienced and those with a social worker. Throughout
            her training, Mary-anne is proud to use her experience of growing up
            in foster care to share meaningful and actionable content based on
            lived experience and psychological perspectives. Sessions provide
            development opportunities that aim to inspire aspirational,
            transformative approaches to care, where young people thrive
            post-adversity.
          </p>
          <h3 className="h3">Topics include:</h3>
          <ul className="">
            <li>
              <strong>Language in Care;</strong> stereotypes, stigma, system
              speak, files journey and child-centred recordings
            </li>
            <li>
              <strong>Transitions;</strong> priorities and possibilities,
              interdependence, teenage development, pathway planning, 'leaving
              care'
            </li>
            <li>
              <strong>Identity;</strong> healthy identity development, system
              barriers, lived experience identity experience in care
            </li>
            <li>
              <strong>Connection;</strong> relational harm vs healing, emotion
              regulation, understanding and overcoming barriers to connection
            </li>
            <li>
              <strong>Participation;</strong> meaningful engagement and voice,
              models of participation
            </li>
            <li>
              <strong>Post-Traumatic Growth;</strong> healing and growth after
              trauma, curating spaces and opportunities for growth
            </li>
            <li>
              <strong>Understanding Adverse Childhood Experiences:</strong> a
              strengths-based approach to research, a personal ACEs journey,
              protective and compensatory measures
            </li>
            <li>
              <strong>Culture of Care;</strong> organisational priorities and
              structure, well-being and reflective practice, making care
              sustainable
            </li>
            <li>
              <strong>Education and Employment;</strong> supporting children in
              care, those with a social worker and care leavers in employment
              and education
            </li>
          </ul>
          <div className="link-container">
            <p className="p--medium p--centered">
              Please enquire for bespoke sessions.
            </p>
            <Link to="/contact" className="button button--white-inverse">
              Book Now
            </Link>
          </div>
          <div className="photos">
            <img
              className="img25"
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_training_1.jpg"
              alt=""
            />
            <img
              className="img50"
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_training_2.jpg"
              alt=""
            />
            <img
              className="img25"
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_training_3.jpg"
              alt=""
            />
          </div>
          <p className="p--medium p--centered p--bold">
            Want to find out more about the audience that is catered for?
          </p>
          <HashLink to="/about#audience" smooth className="p--medium hyperlink">
            <div className="button button--white-inverse ">Audience</div>
          </HashLink>
        </header>
      </div>
      <div className="story-head">
        <h2 className="h2">'A Story Through Care' Core Training Package</h2>
        <p className="p--medium">
          Mary-anne delivers her 8 core ‘A Story Through Care’ sessions,
          detailed below. These are offered as a complete journey for team and
          staff training, from social workers to foster carers, to children's
          homes practitioners and more. Mary-anne also offers contributions to
          the Skills To Foster training, providing a lived experience voice for
          carers at the start of their journey.
        </p>
        <p className="p--medium">
          To request a full brochure, hit the ‘find out more’ button
        </p>
        <Link to="/contact" className="button button--white-inverse">
          Find out more
        </Link>
      </div>
      {/* {window.screen.width < 620 ? (
        <CardsTrainingMobile />
        ) : (
        )} */}
      {/* {window.screen.width >= 620 ? <CardsTrainingDesktop /> : null} */}
      <div className="card-training-mobile-container">
        <CardsTrainingMobile />
      </div>
      <div className="card-training-desktop-container">
        <CardsTrainingDesktop />
      </div>
      <div className="link-container enquire">
        <Link to="/contact" className="button button--white-inverse">
          Enquire now
        </Link>
      </div>
      <ServicesButtons />
    </section>
  );
}

export default ServiceTraining;
