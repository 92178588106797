import Joi from "joi";

const schemaSearchGeneralForm = Joi.object({
  query: Joi.string().trim().allow("").messages({
    "string.base": "please enter a valid query",
    "string.empty": "please enter a query",
  }),
});

export default schemaSearchGeneralForm;
