// ========== Imports ========== //

import "./LoginPage.css";

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../logic";
import withContext from "../utils/withContext";
import { ServerError } from "errors";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaRegisterForm } from "../validators";
import Loggito from "../utils/Loggito";

// ========== Component ========== //

function RegisterPage() {
  // ========== Hook consts ========== //
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaRegisterForm) });

  // ========== other consts ========== //

  const logger = new Loggito("RegisterPage");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  // ========== Function to register user and redirect to login ========== //
  const handleFormSubmit = (data) => {
    try {
      registerUser(
        data.name.trim(),
        data.email.trim(),
        data.password.trim(),
        data.registerCode.trim(),
        function (error) {
          if (error) {
            if (
              error.message.substring(
                error.message.length - 14,
                error.message.length
              ) === "already exists"
            ) {
              toast.warn(error.message);
              logger.warn(error.message);
            } else if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }

          toast.success("User registered successfully");

          navigate("/admin/login");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };
  // ========== jsx ========== //

  return (
    <main className="login-page">
      <div className="login-elements">
        <form
          onSubmit={handleSubmit((data) => handleFormSubmit(data))}
          className="login-form p--medium"
          noValidate={true}
        >
          <label htmlFor="name" className="label p--medium">
            Name
          </label>
          <input
            type="text"
            placeholder="Name"
            name="name"
            className="p--medium"
            {...register("name", {})}
          />
          <p className="input__error-message p--medium">
            {errors.name?.message}
          </p>
          <label htmlFor="email" className="label p--medium">
            Email
          </label>
          <input
            type="email"
            placeholder="Email"
            name="email"
            className="p--medium"
            {...register("email", {})}
          />
          <p className="input__error-message p--medium">
            {errors.email?.message}
          </p>
          <label htmlFor="password" className="label p--medium">
            Password
          </label>
          <input
            type="password"
            placeholder="Password"
            name="password"
            className="input input-item p--medium"
            {...register("password", {})}
          />
          <p className="input__error-message p--medium">
            {errors.password?.message}
          </p>
          <label htmlFor="registerCode" className="label p--medium">
            Register Code
          </label>
          <input
            type="password"
            placeholder="Register Code"
            name="registerCode"
            className="input input-item p--medium"
            {...register("registerCode", {})}
          />
          <p className="input__error-message p--medium">
            {errors.registerCode?.message}
          </p>
          <button type="submit" className="button button--secondary">
            Register
          </button>
        </form>

        <Link to={"/admin/login"} className="button button--white">
          Already have an account?
        </Link>
      </div>
    </main>
  );
}

export default withContext(RegisterPage);
