import "./App.css";

import Context from "./utils/Context";
import { Routes, Route, useNavigate, Navigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AdminMainPage, HomePage } from "./Pages";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import Loggito from "./utils/Loggito.js";

import { useCookies } from "react-cookie";

import CookieConsent, {
  Cookies,
  getCookieConsentValue,
  resetCookieConsentValue,
} from "react-cookie-consent";

// import refreshTokenCount from "./Pages/Helpers/refreshTokenCount";
import refreshTokenHandler from "../src/Components/AdminComponents/Helpers/refreshTokenHandler";

import ScrollToTop from "./utils/ScrollToTop";

function App() {
  const [refreshTokenIntervalId, setRefreshTokenIntervalId] = useState(null);

  // creates an object with the cookies stored plus their values
  // app rerenders on the change of the dependency in the square brackets
  const [cookies, setCookie, removeCookie] = useCookies([
    "cookieConsentCookie",
  ]);

  const modifyCookie = (name, value, expireDays) => {
    const today = new Date();
    const expireDate = new Date(today);
    // change the number to set the expiry date in days from the present
    expireDate.setDate(expireDate.getDate() + expireDays);

    // used to modify a value for a specific cookie, or create a new cookie, can also add options arguments to set expiration etc...
    setCookie(name, value, { expires: expireDate });
  };

  const logger = new Loggito("App");

  const navigate = useNavigate();

  const handleNavigationToLogin = () => {
    navigate("admin/login");

    logger.debug("navigate to login");
  };

  const refreshTokenCount = (action) => {
    let nIntervId;

    if (action === "start") {
      if (!nIntervId) {
        nIntervId = setInterval(refreshTokenHandler, 3480000);
        setRefreshTokenIntervalId(nIntervId);
      }
    }

    if (action === "stop") {
      clearInterval(refreshTokenIntervalId);
      // nIntervId = null;
      setRefreshTokenIntervalId(null);
    }
  };

  const handleLogoutClick = () => {
    delete sessionStorage.token;
    refreshTokenCount("stop");
    logger.debug("sessionStorage.token deleted");

    handleNavigationToLogin();
  };

  return (
    <Context.Provider value={{ refreshTokenCount, modifyCookie, removeCookie }}>
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
        theme="colored"
      />
      <CookieConsent
        expires={150}
        overlay
        location="bottom"
        buttonText="Accept the use of cookies"
        cookieName="cookieConsentCookie"
        style={{ background: "black", minHeight: "100px" }}
        buttonStyle={{
          color: "white",
          fontSize: "13px",
          backgroundColor: "#54785c",
        }}
        enableDeclineButton
        onAccept={() => {
          // TODO: save another cookie to save specific user cookie preferences - e.g. use the useCookie hook from the other react-cookie package - see CookiesPolicy Page
          toast.info(
            "cookies enabled - you can change your cookie preferences any time from the Cookies Policy page"
          );
        }}
        onDecline={() => {
          // TODO: save another cookie to save specific user cookie preferences - e.g. use the useCookie hook from the other react-cookie package - see CookiesPolicy Page
          toast.info(
            "cookies disabled - site functionality may be reduced while cookies are disabled. You can change your cookie preferences any time from the Cookies Policy page"
          );
        }}
        // use debug to make cookie consent panel reappear
        // debug={true}
      >
        This website uses cookies to enhance the user experience. You can change
        your cookie preferences any time from the Cookies Policy Page. <br />
        <Link className="cookie-policy-link" to="/cookiepolicy">
          To read more about our use of cookie please <b>click here.</b>
        </Link>
      </CookieConsent>
      <ScrollToTop>
        <Routes>
          <Route
            path="/admin/*"
            element={<AdminMainPage handleLogoutClick={handleLogoutClick} />}
          />
          <Route path="/*" element={<HomePage />} />
        </Routes>
      </ScrollToTop>
    </Context.Provider>
  );
}

export default App;
