// ========== imports ========== //
import "./WorkingWithMary.css";
import { BsCheckLg } from "react-icons/bs";

import { CTA } from "../";
// ========== component ========== //
function WorkingWithMary() {
  // ========== jsx ========== //
  return (
    <section className="block container working-with-mary">
      <h2 className="h2">Working with Mary-anne</h2>
      <div className="grid grid--1x2--32">
        <div className="plan">
          <div className="card card--secondary">
            <div className="card__body card__body--working-with">
              <p className="p--medium">
                Contributing to an affirming narrative of life after adversity,
                collaboratively curating spaces where young people thrive
                post-trauma.
              </p>
              <ul className="list list--tick">
                <li className="list__item">
                  <BsCheckLg className="check" />
                  Facilitating strengths-based, therapeutic approaches and
                  cultures of care
                </li>
                <li className="list__item">
                  <BsCheckLg className="check" />
                  Supporting the longevity of ‘placements’, halting unplanned
                  endings
                </li>
                <li className="list__item">
                  <BsCheckLg className="check" />
                  Contributing to staff and carer retention
                </li>
                <li className="list__item">
                  <BsCheckLg className="check" />
                  Facilitating carer recruitment
                </li>
                <li className="list__item">
                  <BsCheckLg className="check" />
                  Curating meaningful service design with lived experience at
                  the core
                </li>
                <li className="list__item">
                  <BsCheckLg className="check" />
                  Facilitating deep insight into the understanding and potential
                  of the long-term impact of care experience and early adversity
                </li>
                + More
              </ul>
            </div>
          </div>
        </div>
        <div className="image-container">
          <img
            src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_main_green.jpeg"
            alt="Mary-anne Hobbs"
          />
        </div>
      </div>

      <div>
        <CTA route="/contact" text="Get in touch" />
      </div>
    </section>
  );
}

export default WorkingWithMary;
