import React from "react";
import "./TheGuarantorScheme.css";
import { CTA } from "../";
import ServicesButtons from "../Pages/Services/ServicesButtons";

function TheGuarantorScheme() {
  return (
    <div className="guarantor-scheme content">
      <div className="guarantor-scheme__brochure-container">
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/guarantor-scheme_01.jpg"
          alt=""
        />

        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/guarantor-scheme_02.jpg"
          alt=""
        />

        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/guarantor-scheme_03.jpg"
          alt=""
        />

        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/guarantor-scheme_04.jpg"
          alt=""
        />
      </div>
      <div className="image-caption">
        <p className="p--centered">
          With thanks to{" "}
          <a
            className="link"
            href="https://www.maryannehodd.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mary-anne Hodd
          </a>
          ,{" "}
          <a
            className="link"
            href="https://www.coursewedo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Coursewedo
          </a>{" "}
          and{" "}
          <a
            className="link"
            href="https://www.kent.gov.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kent County Council
          </a>
        </p>
        <p className="p--medium p--centered">
          Link to Landlord Housing Register:{" "}
          <a
            className="link"
            href="https://forms.office.com/r/eUvtmUq9W6"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://forms.office.com/r/eUvtmUq9W6
          </a>{" "}
        </p>
      </div>
      <div className="landlord-registry-container">
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/landlord-register.jpg"
          alt=""
        />
      </div>
      <h3 className="h3">Get in Touch</h3>
      <p className="p--medium">
        If you are a professional who would like to find out more and access the
        Scheme, please complete the contact form and I will be in touch.
      </p>
      <p className="p--medium">
        If you are a young person who would like the Scheme in your area, let me
        know in the contact form and I will support you in making the request.
      </p>
      <CTA text="Get in touch" route="/contact" />
      <ServicesButtons />
    </div>
  );
}

export default TheGuarantorScheme;
