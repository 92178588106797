// ========== imports ========== //
import "./Contact.css";

import React, { useRef, useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import Loggito from "../../utils/Loggito";
import withContext from "../../utils/withContext";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaContactForm } from "../../validators";

import { ServerError } from "errors";
import { addSubscriber } from "../../logic";
// ========== Page ========== //

const SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

function Contact() {
  // ========== Hook consts ========== //
  const [captchaToken, setCaptchaToken] = useState();
  const [emailBeingSent, setEmailBeingSent] = useState(false);

  const form = useRef();
  const captchaRef = useRef(null);

  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
  };
  // const sendEmail = (e) => {

  const handleAddSubscriber = (firstName, surname, email) => {
    try {
      addSubscriber(firstName, surname, email, (error) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else if (
            error.message.slice(0, 32) === "error 409: subscriber with email"
          ) {
            toast.success("Subscribed successfully 😀");
            logger.warn(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }
        reset();
        logger.debug("subscriber added");
        toast.success("Subscribed successfully 😀");
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const sendEmail = async () => {
    const token = captchaRef.current.getValue();

    if (!token) {
      toast.warn("ReCAPTCHA box must be validated");
      return;
    }

    const values = getValues();

    setEmailBeingSent(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/utilities/nodemailercontact`,
        values
      )
      .then((response) => {
        toast.success("Email sent successfully, we'll be in touch soon!");
        reset();
      })
      .catch((e) => {
        logger.error(e.message);
        toast.error("error: sorry, email could not be sent");
      });

    setEmailBeingSent(false);
    if (values.subscribe)
      handleAddSubscriber(values.firstName, values.surname, values.email);

    captchaRef.current.reset();
  };

  // ========== other consts ========== //

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(schemaContactForm),
    defaultValues: {
      guarantor: false,
      training: false,
      keynotes: false,
      consultancy: false,
    },
  });

  const logger = new Loggito("Contact");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div className="">
      <div className="content">
        <p className="p--medium">
          Please get in touch by describing your enquiry below:
        </p>
        <form
          ref={form}
          onSubmit={handleSubmit((data) => sendEmail())}
          className="contact-form"
          noValidate={true}
        >
          <div className="grid grid--1x2 contact-form__grid">
            <div>
              <label className="p--medium-large" htmlFor="firstName">
                * First name:{" "}
              </label>
              <input
                className="p--medium-large"
                type="text"
                placeholder=""
                name="firstName"
                {...register("firstName", {})}
              />
              {errors.firstName && (
                <p className="input__error-message">
                  {errors.firstName?.message}
                </p>
              )}
            </div>
            <div>
              <label className="p--medium-large" htmlFor="surname">
                * Surname:{" "}
              </label>
              <input
                className="p--medium-large"
                type="text"
                placeholder=""
                name="surname"
                {...register("surname", {})}
              />
              {errors.surname && (
                <p className="input__error-message">
                  {errors.surname?.message}
                </p>
              )}
            </div>
            <div>
              <label className="p--medium-large" htmlFor="email">
                * Email address:{" "}
              </label>
              <input
                className="p--medium-large"
                type="email"
                placeholder=""
                name="email"
                {...register("email", {})}
              />
              {errors.email && (
                <p className="input__error-message">{errors.email?.message}</p>
              )}
            </div>
          </div>

          <label className="p--medium-large" htmlFor="message">
            * Message:
          </label>
          <textarea {...register("message", {})} className="p--medium-large" />
          {errors.message && (
            <p className="input__error-message">{errors.message?.message}</p>
          )}

          <div className="serviceList">
            <p className="p--medium">
              If you are interested in a particular service, please select from
              the list below:
            </p>
            <ul className="list">
              <li>
                <input
                  className="contact-form__checkbox"
                  type="checkbox"
                  placeholder=""
                  name="keynotes"
                  {...register("keynotes", {})}
                />
                <label
                  htmlFor="keynotes"
                  className="contact-form__checkbox-label p--medium-large"
                  type="checkbox"
                >
                  Keynotes
                </label>
                {errors.keynotes && (
                  <p className="input__error-message">
                    {errors.keynotes?.message}
                  </p>
                )}
              </li>
              <li>
                <input
                  className="contact-form__checkbox"
                  type="checkbox"
                  placeholder=""
                  name="consultancy"
                  {...register("consultancy", {})}
                />
                <label
                  htmlFor="consultancy"
                  className="contact-form__checkbox-label p--medium-large"
                  type="checkbox"
                >
                  Consultancy
                </label>
                {errors.consultancy && (
                  <p className="input__error-message">
                    {errors.consultancy?.message}
                  </p>
                )}
              </li>
              <li>
                <input
                  className="contact-form__checkbox"
                  type="checkbox"
                  placeholder=""
                  name="training"
                  {...register("training", {})}
                />
                <label
                  htmlFor="training"
                  className="contact-form__checkbox-label p--medium-large"
                  type="checkbox"
                >
                  Training
                </label>
                {errors.training && (
                  <p className="input__error-message">
                    {errors.training?.message}
                  </p>
                )}
              </li>
              <li>
                <input
                  className="contact-form__checkbox"
                  type="checkbox"
                  placeholder=""
                  name="guarantor"
                  {...register("guarantor", {})}
                />
                <label
                  htmlFor="guarantor"
                  className="contact-form__checkbox-label p--medium-large"
                  type="checkbox"
                >
                  The Guarantor Scheme
                </label>
                {errors.guarantor && (
                  <p className="input__error-message">
                    {errors.guarantor?.message}
                  </p>
                )}
              </li>
            </ul>
          </div>
          <div className="subscribe">
            <input
              className="contact-form__checkbox"
              type="checkbox"
              placeholder=""
              name="subscribe"
              {...register("subscribe", {})}
            />
            <label
              htmlFor="subscribe"
              className="contact-form__checkbox-label p--medium-large"
              type="checkbox"
            >
              Subscribe to our newsletter to receive the latest updates:
            </label>
            {errors.subscribe && (
              <p className="input__error-message">
                {errors.subscribe?.message}
              </p>
            )}
          </div>

          <div className="recaptcha-container">
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
          </div>
          {emailBeingSent ? (
            <button
              type="button"
              className="button button--white-inverse-clicked"
            >
              Submit
            </button>
          ) : null}
          {!emailBeingSent ? (
            <button type="submit" className="button button--white-inverse">
              Submit
            </button>
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default withContext(Contact);
