import React from "react";
import { Link } from "react-router-dom";

import "./Services.css";
import "./ServiceKeynotes.css";
import CardsKeynotesDesktop from "./CardsKeynotesDesktop";
import CardsKeynotesMobile from "./CardsKeynotesMobile";
import ServicesButtons from "./ServicesButtons";
import { HashLink } from "react-router-hash-link";

function ServiceKeynotes() {
  return (
    <section className="services keynotes">
      <div className="block container">
        <header className="block__header">
          <h2 className="h2">
            A passionate storyteller, Mary-anne offers 5 core speeches, as
            below.
          </h2>
          <p className="p--medium">
            Mary-anne's keynotes offer unique insights, drawn from her
            experience growing up in the care system and her psychology
            background. Her compelling speeches inspire positive change, leaving
            professionals with a renewed sense of purpose and possibility in
            their practice."
          </p>
          <div className="keynotes-image-container">
            <img
              className="keynotes-image"
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_keynotes_3.jpg"
              alt="Mary-anne Hobbs delivering a keynotes session"
            />
          </div>
          <p className="p--medium">
            “Mary brings so many levels to her work, professional attainment and
            development, empathy and compassion, and lived experience. Mary’s
            journey has shaped her to be a powerful advocate for Children’s
            Rights, and through her spoken word she has the ability to bring an
            entire conference hall to a moment of silence and reflection, and
            then leads them onto a path of wanting to be better for children and
            young people. It was a privilege to share the stage with her, and I
            look forward to it being the first collaboration of many!”.
            <br />
            <strong>
              David Thompson; CEO Inicio Group, Founder The National SaILS
              Conference, Chairman The Supported Accommodation Network &
              National Association of Supported Accommodation Providers.
            </strong>
          </p>
          <p>
            For bespoke keynotes, contact Mary-anne to discuss your agenda and
            theme.
          </p>
          <Link to="/contact" className="button button--white-inverse">
            Enquire
          </Link>
        </header>
      </div>
      <div className="photos">
        <img
          className="img50"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_keynotes_1b.JPG"
          alt=""
        />
        <img
          className="img50"
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/photo_keynotes_2b.JPG"
          alt=""
        />
      </div>
      <p className="p--medium p--centered p--bold">
        Want to find out more about the audience that is catered for?
      </p>
      <HashLink to="/about#audience" smooth className="p--medium hyperlink">
        <div className="button button--white-inverse ">Audience</div>
      </HashLink>
      <div className="cards-keynotes-mobile-container">
        <CardsKeynotesMobile />
      </div>
      <div className="cards-keynotes-desktop-container">
        <CardsKeynotesDesktop />
      </div>
      <div className="link-container">
        <Link to="/contact" className="button button--white-inverse">
          Book Now
        </Link>
      </div>
      <ServicesButtons />
    </section>
  );
}

export default ServiceKeynotes;
