import { BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { NavbarHome } from "../../Components";
import { useLocation, Link } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { FaShareSquare } from "react-icons/fa";
import Loggito from "../../utils/Loggito";

import Titles from "./Titles";

const APP_URL = process.env.REACT_APP_APP_URL;

function HeaderDesktop({ shareTitle, shareText }) {
  const location = useLocation();

  const loggito = new Loggito("HeaderDesktop");

  return (
    <header className="home-page__header desktop--block">
      <div className="web-share">
        <RWebShare
          data={{
            text: shareText,
            url: APP_URL + location.pathname,
            title: shareTitle,
          }}
        >
          <button className="p--medium p--bold">
            <FaShareSquare />
          </button>
        </RWebShare>
      </div>
      <div className="top-bar-container">
        <div className="logo">
          <Link to="/home">
            <img
              className="logo"
              src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-green-round.png"
              alt=""
            />
          </Link>
        </div>
        <Titles />
        <div className="desktop--block social-media-icons-container">
          <div>
            <a
              href="https://twitter.com/maryhodd"
              title="Follow us on Twitter (opens new tab)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsTwitter className="nav__media-icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/mary-anne-hodd-376780105/"
              title="Follow us on LinkedIn (opens new tab)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsLinkedin className="nav__media-icon" />
            </a>
            <a
              href="https://www.instagram.com/maryannehodd/"
              title="Follow us on Instagram (opens new tab)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsInstagram
                className="
              nav__media-icon"
              />
            </a>
          </div>
        </div>
      </div>
      <NavbarHome />

      <div className="title--mobile mobile--block">
        <h1 className="h1">Mary-anne Hodd</h1>
      </div>
    </header>
  );
}

export default HeaderDesktop;
