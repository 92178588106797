// ========== Imports ========== //

import "./AssociatesTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../";
import { CreateAssociate, DeleteAssociate } from "../";
import { getAssociates } from "../../../logic";
import { Pagination } from "../../";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("AssociatesTable");

// Receives the eventId and return the table

function AssociatesTable() {
  // ========== Hook consts ========== //

  const [associatesData, setAssociatesData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 12;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return associatesData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  // ========== other consts ========== //

  function rowTable(associate, index) {
    // ========== JSX ========== //

    return (
      <tr key={associate.route}>
        <td>
          <img src={associate.mainImage.fileUrl} alt={associate.title} />
        </td>
        <td>{associate.name}</td>
        <td>{associate.title}</td>
        <td>{associate.rank}</td>
        <td>{associate.status}</td>
        <td className="buttons-td">
          {
            <>
              {/* <Link
                to={`/admin/associates/view/${associate.route}`}
                className="link"
              >
                View
              </Link>{" "} 
              /
              */}
              <Link
                to={`/admin/associates/edit/${associate.route}`}
                className="link"
              >
                 Edit
              </Link>{" "}
              /
              <DeleteAssociate associate={associate} />
            </>
          }
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getAssociates(sessionStorage.token, (error, associatesArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        // setAssociatesData(associatesArray.map(rowTable));
        setAssociatesData(associatesArray.map(rowTable));
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== JSX ========== //

  if (associatesData !== "") {
    return (
      <div className="associates-table">
        <h1>Manage Associates</h1>
        <CreateAssociate />
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Main Image</th>
              <th>Associate</th>
              <th>Title</th>
              <th>Position (to order associates)</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{currentTableData ? currentTableData : null}</tbody>
        </table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={associatesData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default AssociatesTable;
