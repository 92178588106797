// ========== imports ========== //
import "./HomePage.css";

import HeaderMobileTablet from "./Headers/HeaderMobileTablet";
import HeaderDesktop from "./Headers/HeaderDesktop";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import Loggito from "../utils/Loggito";
import withContext from "../utils/withContext";
import Context from "../utils/Context";
import {
  CookiesPolicy,
  Footer,
  FooterSubscribeForm,
  PrivacyPolicy,
  SiteMap,
  Unsubscribe,
  Contact,
  Associates,
  AFit,
  AboutPage,
  Home,
  TheGuarantorScheme,
  Blog,
  BlogEntry,
  Services,
  ServiceTraining,
  ServiceKeynotes,
  ServiceConsultancy,
} from "../Components";

// ========== Page ========== //

function HomePage({ handleLogoutClick }) {
  // ========== Hook consts ========== //

  const [shareTitle, setShareTitle] = useState("Mary-anne Hodd");
  const [shareText, setShareText] = useState(
    `A link to ${shareTitle} at wwww.maryannehodd.co.uk`
  );

  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("HomePage");

  const handleUpdateShareTitle = (title) => {
    setShareTitle(title);
  };

  // ========== useEffects ========== //

  // ========== Function to navigate between pages ========== //

  // ========== jsx ========== //
  return (
    <Context.Provider
      value={{
        handleUpdateShareTitle,
      }}
    >
      <div>
        <div className="home-page">
          <HeaderMobileTablet />
          <HeaderDesktop shareTitle={shareTitle} shareText={shareText} />

          <main className="home-page__main">
            <Routes>
              <Route path="/associates/*" element={<Associates />} />
              {/* <Route path="/associates2/*" element={<Associates />} /> */}
              <Route path="/afit" element={<AFit />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/home" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/training" element={<ServiceTraining />} />
              <Route path="/keynotes" element={<ServiceKeynotes />} />
              <Route path="/consultancy" element={<ServiceConsultancy />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:route" element={<BlogEntry />} />
              <Route
                path="/the-guarantor-scheme"
                element={<TheGuarantorScheme />}
              />
              <Route path="/contact" element={<Contact />} />

              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/sitemap" element={<SiteMap />} />
              <Route path="/cookiespolicy" element={<CookiesPolicy />} />

              <Route path="/" element={<Home />} />
            </Routes>
          </main>
        </div>
        {location.pathname !== "/contact" && <FooterSubscribeForm />}
        <footer>
          <Footer />
        </footer>
      </div>
    </Context.Provider>
  );
}

export default withContext(HomePage);
