const Joi = require("joi");

function validateFile(file, explain = "file") {
  const schema = Joi.object({
    fileUrl: Joi.string(),
    fileName: Joi.string(),
    fileType: Joi.string(),
  })
    .required()
    .messages({
      "object.pattern.base": `${explain} is not in the correct format`,
      "object.base": `${explain} is not the correct type`,
      "object.empty": `${explain} cannot be empty`,
    });

  const result = schema.validate(file);

  if (result.error) {
    if (result.error.details[0].type === `object.base`) {
      throw new TypeError(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    } else {
      throw new Error(
        `error with ${explain}: ${result.error.details[0].message}`
      );
    }
  }

  return `${explain} validated successfully`;
}

export default validateFile;
