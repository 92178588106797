// ========== imports ========== //
import "./AddAssociate.css";

import Loggito from "../../../utils/Loggito";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
// to install $npm install @hookform/resolvers joi

import { ServerError } from "errors";
import { getAssociateItem, updateAssociate } from "../../../logic";
import { schemaAddAssociateForm } from "../../../validators";

import { Spinner } from "../../";
import { S3Upload } from "../";
import kebabFileName from "../Helpers/kebabFileName";

// ========== Page ========== //

function AddAssociateMary() {
  // ========== Hook consts ========== //

  const [associateData, setAssociateData] = useState();
  const [mainImage, setMainImage] = useState();

  const params = useParams();
  const navigate = useNavigate();

  let route = params.route;

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getAssociateItem(
        sessionStorage.token,
        route,
        (error, associateDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/associatestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/associatestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setAssociateData(associateDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/associatestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);
  // ========== other consts ========== //

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaAddAssociateForm) });

  const logger = new Loggito("AddAssociateMary");
  // ========== useEffects ========== /

  const handleUpdateAssociate = (action, data) => {
    const associateDataToUpdate = {};
    if (action === "details") {
      associateDataToUpdate.name = data.name;
      associateDataToUpdate.title = data.title;
      associateDataToUpdate.newRoute = kebabFileName(data.name);
      associateDataToUpdate.status = data.status;
      associateDataToUpdate.rank = data.rank;
      associateDataToUpdate.url = data.url;
    }
    if (action === "content") {
      associateDataToUpdate.bodyHtml = data;
    }
    if (action === "mainImage") {
      associateDataToUpdate.mainImage = data;
    }

    try {
      updateAssociate(
        sessionStorage.token,
        action,
        route,
        associateDataToUpdate,

        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }

          if (action === "details") {
            const newRoute = `/admin/associates/edit/${kebabFileName(
              data.name
            )}`;
            navigate(newRoute);
          }
          toast.success("Associate updated successfully");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const handleMainImageUpload = (file) => {
    setMainImage(file);
    handleUpdateAssociate("mainImage", file);
  };

  const handleFormSubmit = (data) => {
    handleUpdateAssociate("details", data);
  };

  // ========== jsx ========== //
  if (associateData) {
    return (
      <div className="add-associate">
        <div className="content">
          <h2 className="h2">Add Associate</h2>
          <h3 className="h3">Please complete the details below:</h3>
          <form
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            className="add-associate-form"
            noValidate={true}
          >
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                placeholder=""
                name="name"
                defaultValue={associateData.name}
                {...register("name", {})}
              />
              {errors.name && (
                <p className="input__error-message">{errors.name?.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                placeholder=""
                name="title"
                defaultValue={associateData.title}
                {...register("title", {})}
              />
              {errors.title && (
                <p className="input__error-message">{errors.title?.message}</p>
              )}
            </div>

            <div>
              <label className="p--medium label--select" htmlFor="status">
                Select status:
              </label>
              <select
                className="p--medium input--select"
                {...register("status")}
                name="status"
                defaultValue={associateData.status}
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
                <option value="inactive">Inactive</option>
              </select>
              {errors.status && (
                <p className="input__error-message">{errors.status?.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="rank">Position:</label>
              <input
                type="number"
                placeholder=""
                name="rank"
                defaultValue={associateData.rank}
                {...register("rank", {})}
              />
              {errors.rank && (
                <p className="input__error-message">{errors.rank?.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="url">Link to site:</label>
              <input
                type="text"
                placeholder=""
                name="url"
                defaultValue={associateData.url}
                {...register("url", {})}
              />
              {errors.url && (
                <p className="input__error-message">{errors.url?.message}</p>
              )}
            </div>

            <button
              // href="home.html"
              type="submit"
              className="button button--white-inverse"
            >
              Save
            </button>
          </form>
          <h3 className="h3">Biography Body:</h3>

          {/* // for demonstration purposes only */}
          {/* {body && <div>{parse(body)}</div>} */}
          {/* <div className="block upload-files--flex"> */}
          <div className="block">
            <S3Upload
              title="Upload Main Image (optional)"
              onFileUpload={handleMainImageUpload}
              type="image"
              currentFileName={associateData.mainImage.fileName}
            />
            <div></div>
          </div>
        </div>
      </div>
    );
  } else return <Spinner />;
}

export default AddAssociateMary;
