import React from "react";
import { Link } from "react-router-dom";
import "./ServicesOverview.css";
import { CTA } from "../../";

function ServicesOverview(props) {
  return (
    <div className="services-overview">
      <h2 className="h2 p--centered">Services</h2>
      {/* Icons Grid */}
      <div className="grid grid--1x4 services-grid">
        <Link to="/training" className="image-text__container service__link">
          <img
            className="leaf-badge image-icon"
            src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_training.png"
            alt=""
          />

          <p className="p--large p--centered">Training</p>
        </Link>
        <Link to="/keynotes" className="image-text__container service__link">
          <img
            className="leaf-badge image-icon"
            src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_keynotes.png"
            alt=""
          />

          <p className="p--large p--centered">Keynotes</p>
        </Link>
        <Link to="/consultancy" className="image-text__container service__link">
          <img
            className="leaf-badge image-icon"
            src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_mh.png"
            alt=""
          />

          <p className="p--large p--centered">Consultancy</p>
        </Link>
        <Link
          to="/the-guarantor-scheme"
          className="image-text__container service__link"
        >
          <img
            className="leaf-badge image-icon"
            src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/badge_guarantor.png"
            alt=""
          />

          <p className="p--large p--centered">The Guarantor Scheme</p>
        </Link>
      </div>
      <CTA route="/contact" text="Get in touch" />
      {/* <h3 className="h3 p--centered">Mary-anne’s work has…</h3>
      <ul className="grid grid--1x2x3 myworkhas">
        <li className="p--medium">
          Supported the longevity of placements, halting unplanned endings
        </li>
        <li className="p--medium">Contributed to staff and carer retention</li>
        <li className="p--medium">Facilitated carer recruitment</li>
        <li className="p--medium">
          Curated meaningful service design with lived experience at the core
        </li>
        <li className="p--medium">
          Facilitated a deeper insight into the understanding and potential of
          the long-term impact of care and early adversity
        </li>
        <li className="p--medium">
          Facilitated strengths-based, therapeutic approaches and culture of
          care
        </li>
      </ul>
      <p className="p--medium p--centered">
        Proudly, my work is contributing to a new narrative of the possibilities
        of our care experience, supporting environments to curate spaces where
        young people thrive after trauma, where the adult around them see the
        potential and possibility.
      </p>
      <CTA route="/contact" text="Get in touch" /> */}
    </div>
  );
}

export default ServicesOverview;
