// The first real value in the array is at index 2, index[0] and [1] are copied from the end
// The final value is copied from the first real value

const mentions = [
  {
    url: "https://www.vice.com/en/article/wxdq7m/the-woman-fighting-for-care-leavers-right-to-housing",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-vice.jpeg",
    title: "The Woman Fighting for Care Leavers' Right to Housing.",
  },
  {
    url: "https://www.bigissue.com/news/housing/care-leavers-housing-the-guarantor-scheme/",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-big-issue.png",
    title:
      "Care leavers often struggle to find a home. Here’s an idea that gives them a fighting chance",
  },
  {
    url: "https://wearebluecabin.com/latest/if-the-adults-dont-dare-to-dream-for-us-how-can-we-dream-for-ourselves",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-bluecabin.jpeg",
    title:
      "'If the adults don’t dare to dream for us, how can we dream for ourselves?'",
  },
  {
    url: "https://wearebluecabin.com/resource/podcast-season-3-episode-2-thriving-after-adversity",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-bluecabin-podcast.jpeg",
    title: "'Thriving after adversity' with Blue Cabin",
  },
  {
    url: "https://dialogueltd.co.uk/independence-interdependence/",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo_dialogue_padding.png",
    title: "From Independence to Interdependence.",
  },
  {
    url: "https://www.devonlive.com/news/devon-news/mary-anne-changed-system-devon-5355783",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-devon-live.jpg",
    title:
      "Mary-anne has changed the system so Devon care leavers can find the home they want.",
  },
  {
    url: "https://theeverydaymagazine.co.uk/opinion/the-first-24hrs-in-foster-care-a-childs-perspective",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-the-everyday.jpeg",
    title: "The first 24 hours in foster care; a child’s perspective.",
  },
  {
    url: "https://issuu.com/socialworknews/docs/swn_summer_2021_digital/s/12517722",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-social-work-news.jpeg",
    title: "Expect more from young care leavers.",
  },
  {
    url: "https://theeverydaymagazine.co.uk/fictionandpoetry/schizophrenia-by-mary-anne-hodd",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-the-everyday.jpeg",
    title: "Schizophrenia: Poem",
  },
  {
    url: "https://www.vice.com/en/article/wxdq7m/the-woman-fighting-for-care-leavers-right-to-housing",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-vice.jpeg",
    title: "The Woman Fighting for Care Leavers' Right to Housing.",
  },
  {
    url: "https://www.bigissue.com/news/housing/care-leavers-housing-the-guarantor-scheme/",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-big-issue.png",
    title:
      "Care leavers often struggle to find a home. Here’s an idea that gives them a fighting chance",
  },
  {
    url: "https://wearebluecabin.com/latest/if-the-adults-dont-dare-to-dream-for-us-how-can-we-dream-for-ourselves",
    imageSource:
      "https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-bluecabin.jpeg",
    title:
      "'If the adults don’t dare to dream for us, how can we dream for ourselves?'",
  },
];

export default mentions;
