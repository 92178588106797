import Joi from "joi";

const schemaAddAssociateForm = Joi.object({
  name: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid name",
    "string.empty": "please enter a name",
  }),
  title: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid title",
    "string.empty": "please enter a title",
  }),
  url: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid url",
    "string.empty": "please enter a url",
  }),
  status: Joi.string()
    .trim()
    .allow("")
    .valid("", "draft", "published", "inactive")
    .min(1)
    .messages({
      "string.base": "please select a valid status",
      "string.empty": "please select a status",
    }),
  rank: Joi.number().min(1).messages({
    "number.base": "please enter a valid rank",
    "number.empty": "please enter a rank",
  }),
  bodyHtml: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please write a valid body",
    "string.empty": "please add a body",
  }),
});

export default schemaAddAssociateForm;
