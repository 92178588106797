import Joi from "joi";

const schemaUpcomingEventsForm = Joi.object({
  title: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid title",
    "string.empty": "please enter a title",
  }),
  date: Joi.string().trim().allow("").messages({
    "string.base": "please select a valid date and start time",
    "string.empty": "please select a date and start time",
  }),
  finishTime: Joi.string().trim().allow("").messages({
    "string.base": "please select a valid finish time",
    "string.empty": "please select a finish time",
  }),
  host: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid host",
    "string.empty": "please enter a host",
  }),
  link: Joi.string().trim().allow("").min(1).messages({
    "string.base": "please enter a valid url",
    "string.empty": "please enter a url",
  }),
  rank: Joi.number().min(1).messages({
    "number.base": "please enter a valid rank",
    "number.empty": "please enter a rank",
  }),
});

export default schemaUpcomingEventsForm;
