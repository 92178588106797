import React from "react";
import "./UpcomingEvents.css";
import { useState, useEffect } from "react";
import { getUpcomingEventsClient } from "../../../logic/index";
import { ServerError } from "errors";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";

function UpcomingEvents({}) {
  const [eventsData, setEventsData] = useState([]);

  const logger = new Loggito("UpcomingEvents");

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getUpcomingEventsClient((error, eventsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        setEventsData(eventsArray);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  if (eventsData.length > 0) {
    return (
      <div className="upcoming-events">
        <h2 className="h2 p--centered">Upcoming Events on Eventbrite</h2>
        <p className="p--medium p--centered">
          Follow the links below to sign up to Mary-anne's upcoming open
          bookings
        </p>
        <div className="events-container">
          {eventsData[0] ? (
            <div className="event-info">
              <a href="">
                <p className="p--large title">
                  <strong>{eventsData[0].title}</strong>
                </p>
                <p className="p--medium">
                  <strong>Date: </strong>
                  {new Date(eventsData[0].date).toUTCString().slice(0, 17)}
                </p>
                <p className="p--medium">
                  <strong>Time: </strong>
                  {new Date(eventsData[0].date)
                    .toUTCString()
                    .slice(17, 23)} to {eventsData[0].finishTime}
                </p>
                <p className="p--medium">
                  <strong>By </strong>
                  {eventsData[0].host}
                </p>
              </a>
              <a
                href={eventsData[0].link}
                className="button button--white-inverse"
                target="_blank"
                rel="nofollow noreferrer noopener external"
              >
                More info
              </a>
            </div>
          ) : null}
          {eventsData[1] ? (
            <div className="event-info">
              <a href="">
                <p className="p--large title">
                  <strong>{eventsData[1].title}</strong>
                </p>
                <p className="p--medium">
                  <strong>Date: </strong>
                  {new Date(eventsData[1].date).toUTCString().slice(0, 17)}
                </p>
                <p className="p--medium">
                  <strong>Time: </strong>
                  {new Date(eventsData[1].date)
                    .toUTCString()
                    .slice(17, 23)} to {eventsData[1].finishTime}
                </p>
                <p className="p--medium">
                  <strong>By </strong>
                  {eventsData[1].host}
                </p>
              </a>
              <a
                href={eventsData[1].link}
                className="button button--white-inverse"
                target="_blank"
                rel="nofollow noreferrer noopener external"
              >
                More info
              </a>
            </div>
          ) : null}
          {eventsData[2] ? (
            <div className="event-info">
              <a href="">
                <p className="p--large title">
                  <strong>{eventsData[2].title}</strong>
                </p>
                <p className="p--medium">
                  <strong>Date: </strong>
                  {new Date(eventsData[2].date).toUTCString().slice(0, 17)}
                </p>
                <p className="p--medium">
                  <strong>Time: </strong>
                  {new Date(eventsData[2].date)
                    .toUTCString()
                    .slice(17, 23)} to {eventsData[2].finishTime}
                </p>
                <p className="p--medium">
                  <strong>By </strong>
                  {eventsData[2].host}
                </p>
              </a>
              <a
                href={eventsData[2].link}
                className="button button--white-inverse"
                target="_blank"
                rel="nofollow noreferrer noopener external"
              >
                More info
              </a>
            </div>
          ) : null}
        </div>
        <div className="line--secondary"></div>
      </div>
    );
  } else return null;
}

export default UpcomingEvents;
