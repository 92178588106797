// ========== imports ========== //
import "./SiteMap.css";

import { useNavigate, Link } from "react-router-dom";
// ========== component ========== //
function SiteMap() {
  // ========== hook consts ========== //

  const navigate = useNavigate();
  // ========== other consts ========== //

  const routes = [
    { route: "/Home", component: "/Home", key: "/Home", title: "Home" },
    {
      route: "/Services",
      component: "/Services",
      key: "/Services",
      title: "Services",
    },
    {
      route: "/Training",
      component: "/Training",
      key: "/Training",
      title: "Training",
    },
    {
      route: "/Keynotes",
      component: "/Keynotes",
      key: "/Keynotes",
      title: "Keynotes",
    },
    {
      route: "/Consultancy",
      component: "/Consultancy",
      key: "/Consultancy",
      title: "Consultancy",
    },
    {
      route: "/Blog",
      component: "/Blog",
      key: "/Blog",
      title: "Blog",
    },
    {
      route: "/The-Guarantor-Scheme",
      component: "/TheGuarantorScheme",
      key: "/TheGuarantorScheme",
      title: "The Guarantor Scheme",
    },
    {
      route: "/AFIT",
      component: "/AFIT",
      key: "/AFIT",
      title: "AFIT",
    },
    {
      route: "/Associates",
      component: "/Associates",
      key: "/Associates",
      title: "Associates",
    },
    {
      route: "/Contact",
      component: "/Contact",
      key: "/Contact",
      title: "Contact",
    },
    {
      route: "/PrivacyPolicy",
      component: "/PrivacyPolicy",
      key: "/PrivacyPolicy",
      title: "Privacy Policy",
    },
    {
      route: "/CookiesPolicy",
      component: "/CookiesPolicy",
      key: "/CookiesPolicy",
      title: "Cookies Policy",
    },
    {
      route: "/Unsubscribe",
      component: "/Unsubscribe",
      key: "/Unsubscribe",
      title: "Unsubscribe",
    },
  ];

  // ========== jsx ========== //
  return (
    <nav className="site-map content">
      <ul className="">
        {routes.map((element) => {
          return (
            <li key={element.key}>
              <Link
                className="link"
                to={`/${element.route.slice(1).toLowerCase()}`}
              >
                {element.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default SiteMap;
