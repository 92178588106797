// ========== Imports ========== //

import "./DeleteAssociate.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { deleteAssociate } from "../../../logic";
import { toast } from "react-toastify";
import Loggito from "../../../utils/Loggito";
import { ServerError } from "errors";

import { deleteFile } from "react-s3";
// ========== Component ========== //

//Receives the eventId and try to delete that one in our database
function DeleteAssociate({ associate }) {
  // ========== Hook consts ========== //

  const [deleteDivStyle, setDeleteDivStyle] = useState({
    display: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-50px",
    width: "100px",
    height: "100px",
  });
  const [pageBlockStyle, setPageBlockStyle] = useState({
    display: "none",
  });

  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("DeleteAssociate");

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = process.env.REACT_APP_REGION;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };
  // const eventIdString = eventId.toString();

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  function refreshPage() {
    window.location.reload(false);
  }

  // ========== Function to block the screen and show the accept/decline popup ========== //

  function changeDivStatus() {
    if (deleteDivStyle.display === "none") {
      setPageBlockStyle({
        display: "block",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "black",
        opacity: "50%",
      });
      setDeleteDivStyle({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        color: "var(--color-primary-text)",
        flexDirection: "column",
        boxShadow: "0 0 20px 10px hsl(0deg 0% 15%)",
        position: "fixed",
        top: "50%",
        left: "50%",
        padding: "20px",
        marginTop: "-75px",
        marginLeft: "-100px",
        width: "300px",
        height: "300px",
        borderRadius: "10px",
      });
    } else {
      setDeleteDivStyle({ display: "none" });
      setPageBlockStyle({ display: "none" });
    }
  }

  // ========== Functions ========== //

  function deleteAssociateFromDatabase(location) {
    try {
      deleteAssociate(sessionStorage.token, associate.route, (error) => {
        // TODO: onload on logic is never triggered even if the delete event and response is sent correctly
        // TODO: code never reaches this far, it never returns to this function after sending
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
            if (error.message === "session timed out, please log in again") {
              delete sessionStorage.token;
              window.location.reload(false);
            }
          }
          return;
        }
        // TODO: delete files from S3
        if (associate.mainImage.fileName !== "") {
          deleteFile(associate.mainImage.fileName, config)
            .then((response) => {
              logger.info(response);
            })
            .catch((err) => console.error(err));
        }
        // TODO: these files are not deleting from DB

        // .
        toast.success("The associate was successfully deleted");
        // handleGetSubscribers();
        refreshPage();
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  }

  // ========== JSX ========== //

  return (
    <div className="delete-associate">
      <button className="button button--table" onClick={changeDivStatus}>
        Delete
      </button>
      <div style={pageBlockStyle}></div>
      <div style={deleteDivStyle} className="p--medium">
        <p>You are going to delete the associate.</p>
        <p> Are you sure? </p>
        <div>
          <button
            className="button button--white-inverse button--delete-associate"
            onClick={() => deleteAssociateFromDatabase(location)}
          >
            Yes
          </button>
          <button
            className="button button--white-inverse button--delete-associate"
            onClick={changeDivStatus}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteAssociate;
