import React from "react";
import "./CardsTrainingMobile.css";

function CardsTrainingMobile(props) {
  return (
    <section className="cards-training-mobile">
      <div className="">
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-1-lets-talk-words_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">Let’s Talk Words, Language in Care</h3>
          <p className="p--cards">
            This session focuses on the impact of language, recordings, and
            reports for care experienced children and young people. Delegates
            will learn about the challenges of negative or stigmatising language
            and discover practical strategies for using mindful, strengths-based
            language. This will emphasise professional curiosity and therapeutic
            writing techniques to provide a comprehensive picture of the young
            person’s reality. By the end of the session, attendees will have a
            deeper understanding of the importance of language in creating
            positive outcomes for care experienced children and young people and
            practical tools for using strengths-based language in their work.
          </p>
        </div>
      </div>
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-2-amplifying-the-childs-voice_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            Amplifying the Child’s Voice: Participation and Meaningful
            Engagement{" "}
          </h3>
          <p className="p--cards">
            This session aims to equip professionals with practical strategies
            for amplifying the voices of children and young people in care,
            considering the topic of participation. Through a lived experience
            exploration, case studies and wider research, delegates will gain a
            deeper understanding of the challenges faced by care experienced
            individuals and explore methods for building trust and rapport,
            actively listening, and involving them in decision-making processes.
            The session explores theories of participation and will provide
            techniques and resources to overcome challenges and facilitate
            meaningful engagement.
          </p>
        </div>
      </div>
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-3-beyond-the-score_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            Beyond the Score; Understanding Adverse Childhood Experiences
          </h3>
          <p className="p--cards">
            This session aims to provide professionals with a strengths-based
            understanding of Adverse Childhood Experiences (ACEs) and their
            impact on later life outcomes. Through Mary-anne's personal ACEs
            journey, delegates will gain insights into preventative and
            compensatory measures, relational healing, and therapeutic care
            approaches that prioritise the child's voice and potential for
            positive change. Delegates will leave equipped with an understanding
            of key research and developmental factors of early adversity and
            will gain practical resources and tools to support healing and
            resilience.
          </p>
        </div>
      </div>
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-4-the-making-of-me_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">The Making of Me; Identity in Care and Beyond</h3>
          <p className="p--cards">
            This session explores the unique challenges and opportunities faced
            by care-experienced children and young people in forming and
            expressing their identities. Through a developmental and
            trauma-responsive lens, delegates will gain insights into the impact
            of early life experiences, systemic and societal factors on identity
            formation. The session will equip professionals with practical
            strategies to create safe and supportive environments, build
            trusting relationships, and empower young people in developing their
            autonomy and sense of self. Delegates will leave with a deeper
            understanding of the complex nature of identity formation and the
            tools to promote positive outcomes for young people in care and
            beyond.
          </p>
        </div>
      </div>
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-5-relational-healing_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            From Relational Harm to Relational Healing; When We Connect.
          </h3>
          <p className="p--cards">
            This session focuses on the importance of relational healing and
            connection when supporting children and young people who have
            experienced adversity. Delegates will gain a care experienced
            exploration of personal and systemic barriers to connection and will
            acquire practical tools and strategies to curate environments that
            overcome these barriers. The session aims to empower professionals
            to cultivate a sense of connectedness in their practice and
            facilitate relational healing and trauma-aware care for those they
            support.
          </p>
        </div>
      </div>
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-6-the-journey-beyond_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            The Journey Beyond: Transitions, Interdependence, Priorities and
            Possibilities
          </h3>
          <p className="p--cards">
            The purpose of this session is to provide delegates with a deeper
            understanding of the unique challenges faced by young people in
            transition and to explore alternative approaches to independence.
            Through practical examples and reflective exercises, delegates will
            learn how to place interdependence at the core of transition
            planning and support the social and emotional skill development of
            young people. The session is relevant for anyone working with young
            people in transitions, including care leavers and those moving
            between placements.
          </p>
        </div>
      </div>
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-7-healing-after-adversity_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">Healing After Adversity: Post-Traumatic Growth</h3>
          <p className="p--cards">
            The purpose of this session is to explore the concept of
            post-traumatic growth in children and young people who have
            experienced early adversity, and to understand the factors,
            characteristics, and environments that support post-traumatic
            growth. By the end of the session, delegates will have a deeper
            understanding of what post-traumatic growth is and how it can
            manifest in children, as well as practical resources and strategies
            for creating environments that facilitate post-traumatic growth.
          </p>
        </div>
      </div>
      <div className="">
        {/* <input className="flip" type="checkbox" /> */}
        <img
          src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-8-supporting-the-educational-achievements_m.jpg"
          alt=""
        />
        <div className="">
          <h3 className="h3">
            Supporting the Educational Achievements of Care Experienced Young
            People
          </h3>
          <p className="p--cards">
            This training session is designed to help delegates better
            understand the unique challenges faced by care experienced young
            people in their academic journeys. Through this session, delegates
            will gain practical skills and evidence-based strategies for
            promoting positive academic outcomes and supporting the overall
            well-being of care experienced young people. Topics covered include
            the impact of trauma and attachment issues, building positive
            relationships, promoting self-advocacy, and tailored support for
            academic success. By the end of this session, delegates will be
            equipped to make a meaningful impact in the lives of care
            experienced young people, helping them to achieve their full
            potential in education and beyond.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CardsTrainingMobile;
