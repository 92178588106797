import React from "react";
import "./NextSteps.css";
import { Link } from "react-router-dom";

function NextSteps(props) {
  return (
    <div className="block next-steps">
      <p className="p--medium-large p--centered">
        <b>Want to find out more?</b> - Get in touch to find out more about
        Mary-anne's services.
      </p>
      {/* Buttons */}
      <div className="flex--centered">
        <Link
          to="/contact"
          className="button button--white-inverse button--next"
        >
          Get in touch
        </Link>
      </div>
    </div>
  );
}

export default NextSteps;
